import React, { useEffect, useState } from "react";
import { Button, Rate, Tabs, message, Modal, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import Layout from "../../Components/Layout/Layout";
import { fetchBookings } from "../../redux/slices/bookingsSlice";
import { imageurl } from "../../apis/host";
import { AcceptRequest, RejectRequest } from "../../apis/api";
import "./Bookings.scss";
import DummyUser from "../../assest/dunmmyuser.png";
import { CiCalendar } from "react-icons/ci";
import moment from "moment";
const { TabPane } = Tabs;
const { TextArea } = Input;
const BookingsforBeautician = ({ type }) => {
  const [activeTab, setActiveTab] = useState("UPCOMING");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  const { bookings, loading } = useSelector((state) => state.bookings);
  // const profile = useSelector((state) => state.user.profile?.data);
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  useEffect(() => {
    dispatch(fetchBookings({ Token: Token, status: activeTab }));
  }, [dispatch, Token, activeTab]);
  const getButtonStyle = (status) => {
    switch (status) {
      case "UPCOMING":
        return "newRequestBtn";
      case "PENDING":
        return "upcomingBtn";
      case "COMPLETED":
        return "completedBtn";
      default:
        return "defaultBtn";
    }
  };
  const handleAcceptRequest = (id) => {
    AcceptRequest(Token, id)
      .then((res) => {
        dispatch(fetchBookings({ Token: Token, status: activeTab }));
        message.success(res?.data?.message);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleRejectRequest = () => {
    RejectRequest(Token, rejectionReason, selectedBookingId)
      .then((res) => {
        dispatch(fetchBookings({ Token: Token, status: activeTab }));
        message.success(res?.data?.message);
        setIsModalVisible(false);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const showRejectModal = (id) => {
    setSelectedBookingId(id);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setRejectionReason("");
  };
  const renderBookingCards = () => {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (bookings.length === 0) {
      return <p>No data available</p>;
    }
    return bookings.map((item, index) => (
      <div className="bookingCard" key={index}>
        <div className="profileContainer">
          <div className="profile">
            <img
               src={
                item?.appointment_user?.user_attachments?.length === 0
                  ? DummyUser
                  : item?.appointment_user?.user_attachments[0]?.file_name?.includes(
                      "https:"
                    )
                  ? item?.appointment_user?.user_attachments[0]?.file_name
                  : `${imageurl}${item?.appointment_user?.user_attachments[0]?.file_name}`
              }
              alt=""
              className="Appointment_profile_picture"
            />
            <div className="name">
              <h6>{item?.appointment_user?.user_profile?.name}</h6>
              <Rate
                disabled
                defaultValue={
                  item?.appointment_user?.user_profile?.overall_ratings
                }
              />
            </div>
          </div>
          <Button
            className={getButtonStyle(activeTab)}
            style={{ borderRadius: "16px" }}
          >
            {activeTab}
          </Button>
        </div>
        <div>
          <div className="Date_time_section">
            <div>
              <CiCalendar size={20} />
            </div>
            <div className="date_time">
              <h6 className="date">
                {moment(item?.appointment_slot?.date).format("D MMMM YYYY")}
              </h6>
              <h6 className="date">|</h6>
              <h6 className="date">
                {item?.appointment_slot?.start_time_local}
              </h6>
            </div>
          </div>
          <p className="text">
          {item?.appointment_user?.user_profile?.about==="undefined"?"---":item?.appointment_user?.user_profile?.about.substring(0, 150)}
          </p>
        </div>
        {activeTab === "PENDING" && (
          <div className="btns">
            <Button
              className="rejectBtn"
              onClick={() => showRejectModal(item.id)}
            >
              Reject
            </Button>
            <Button
              className="acceptBtn"
              onClick={() => handleAcceptRequest(item.id)}
            >
              Accept
            </Button>
          </div>
        )}
      </div>
    ));
  };
  return (
    <Layout  type="self">
      <div className="Booking">
        <h4 className="heading">Bookings</h4>
        <Tabs defaultActiveKey="UPCOMING" onChange={(key) => setActiveTab(key)}>
          <TabPane tab="Upcoming" key="UPCOMING">
            {renderBookingCards()}
          </TabPane>
          <TabPane tab="Pending" key="PENDING">
            {renderBookingCards()}
          </TabPane>
          <TabPane tab="Completed" key="COMPLETED">
            {renderBookingCards()}
          </TabPane>
        </Tabs>
        <Modal
          title="Reject Booking"
          visible={isModalVisible}
          onOk={handleRejectRequest}
          onCancel={handleCancel}
        >
          <TextArea
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Please provide the reason for rejection"
          />
        </Modal>
      </div>
    </Layout>
  );
};
export default BookingsforBeautician;
