import React, { useState, useEffect } from "react";
import { Row, Col, Input } from "antd";
import { Container } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { FaPaperclip } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { ref, push, set, onValue, get } from "firebase/database";
import { db } from "../firebase";
import ChatsList from "./ChatList";
import Login from "../AuthLayout/Login/Login";
import AuthLayout from "../AuthLayout/AuthLayout";
import ChatDummyIcon from "../../assest/Chatbot.gif";
import "./Inbox.scss"; // Assuming SCSS styles for the Inbox component
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../apis/host";
import DummyUser from "../../assest/dunmmyuser.png";
import { Token } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Inbox = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [newMessage, setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.user?.profile?.data);
  const navigate = useNavigate();

  const sender = profile?.id; // Replace with dynamic sender ID
  useEffect(() => {
    if (Token) {
      dispatch(fetchSelfProfile(Token));
    } else {
      navigate("/login");
    }

    if (selectedUser) {
      const chatPath1 = `chats/${sender}_${selectedUser}`;
      const chatPath2 = `chats/${selectedUser}_${sender}`;
      const messagesRef1 = ref(db, chatPath1);
      const messagesRef2 = ref(db, chatPath2);

      onValue(messagesRef1, (snapshot) => {
        if (snapshot.exists()) {
          const messages = Object.values(snapshot.val());
          setChatMessages(messages);
        }
      });

      onValue(messagesRef2, (snapshot) => {
        if (snapshot.exists()) {
          const messages = Object.values(snapshot.val());
          setChatMessages(messages);
        }
      });
    }
  }, [selectedUser]);

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      return;
    }
    // Assuming you have a function to get user profile details
    const senderProfile = profile?.user_attachments[0]?.file_name; // Replace with actual sender profile image URL
    const receiverProfile = selectedImage; // Replace with actual receiver profile image URL
    const senderName = profile?.user_profile?.name; // Replace with actual sender name
    const receiverName = selectedName; // Replace with actual receiver name

    const messageObject = {
      message: newMessage.trim(),
      read: false,
      receiver: selectedUser,
      receiver_profile: receiverProfile,
      receiver_name: receiverName,
      sender,
      sender_name: senderName,
      sender_profile: senderProfile,
      timestamp: new Date().getTime(), // Use milliseconds for timestamp
    };
    const chatPath1 = `chats/${sender}_${selectedUser}`;
    const chatPath2 = `chats/${selectedUser}_${sender}`;

    const chatRef1 = ref(db, chatPath1);
    const chatRef2 = ref(db, chatPath2);

    try {
      const snapshot1 = await get(chatRef1);

      if (snapshot1.exists()) {
        await push(chatRef1, messageObject);
      } else {
        const snapshot2 = await get(chatRef2);
        if (snapshot2.exists()) {
          await push(chatRef2, messageObject);
        } else {
          await set(chatRef1, true);
          await push(chatRef1, messageObject);
        }
      }
      setNewMessage("");
    } catch (e) {
      console.error("Error sending message: ", e);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleUserClick = (userId, UserName, UserImage) => {
    setSelectedUser(userId);
    setSelectedName(UserName);
    setSelectedImage(UserImage);
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  return (
    <>
      <div className="inboxContainer">
        <Container>
          <h3>Inbox</h3>
          <Row>
            <Col span={6} className="firstColumn">
              {/* <Input placeholder="Search here..." prefix={<CiSearch />} /> */}
              <ChatsList onUserClick={handleUserClick} />
            </Col>
            <Col span={18} className="secondColumn">
              <div className="chatSection">
                {selectedUser ? (
                  <div className="chatContainer">
                    <div className="selectedUser">
                      <div className="selectedUserHeader">
                        <div className="user_icon_name">
                          <div>
                            <img
                              // src={`${imageurl}${selectedImage}`}
                              src={
                                selectedImage === null
                                  ? DummyUser
                                  : selectedImage?.includes("https:")
                                  ? selectedImage
                                  : `${imageurl}${selectedImage}`
                              }
                              className="user_image"
                              alt=""
                            />
                          </div>

                          <div>
                            <div className="userName">{selectedName}</div>
                          </div>
                        </div>
                      </div>
                      <div className="scrollableContainer">
                        {chatMessages.map((msg, index) => (
                          <div
                            key={index}
                            className={`message ${
                              msg.sender === sender ? "sent" : "received"
                            }`}
                          >
                            <div className="messageHeader"></div>
                            <div className="messageContent">{msg.message}</div>
                          </div>
                        ))}
                      </div>
                      <div className="messageInputContainer">
                        <Input
                          placeholder="Type something..."
                          value={newMessage}
                          onChange={handleInputChange}
                          suffix={<FaPaperclip className="attachmentIcon" />}
                          className="messageInput"
                          onKeyPress={handleKeyPress}
                        />
                        <IoSend className="sendButton" onClick={sendMessage} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="placeholderContainer">
                    <img src={ChatDummyIcon} alt="" />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Inbox;
