// // import React, { useState, useEffect } from 'react';
// // const LocationTracker = () => {
// //   const [latitude, setLatitude] = useState(null);
// //   const [longitude, setLongitude] = useState(null);
// //   const [address, setAddress] = useState('');
// //   const [error, setError] = useState(null);
// //   useEffect(() => {
// //     const getLocation = async () => {
// //       try {
// //         if (navigator.geolocation) {
// //           navigator.geolocation.getCurrentPosition(
// //             position => {
// //               setLatitude(position.coords.latitude);
// //               setLongitude(position.coords.longitude);
// //               getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
// //             },
// //             error => {
// //               console.error('Error getting geolocation:', error);
// //               setError(error.message);
// //             }
// //           );
// //         } else {
// //           setError('Geolocation is not supported by this browser.');
// //         }
// //       } catch (error) {
// //         console.error('Error:', error);
// //         setError('Error getting geolocation.');
// //       }
// //     };
// //     getLocation();
// //     return () => {
// //       // Cleanup if needed
// //     };
// //   }, []);
// //   const getAddressFromCoordinates = async (lat, lng) => {
// //     try {
// //       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBwWrA2Zwyk_XXqBzGdp7B1Bt9mXCLG5CA`);
// //       const data = await response.json();
// //       if (data.status === 'OK' && data.results[0]) {
// //         setAddress(data.results[0].formatted_address);
// //       } else {
// //         setAddress('Address not found');
// //       }
// //     } catch (error) {
// //       console.error('Error fetching address:', error);
// //     }
// //   };
// //   return (
// //     <div>
// //       {error && <p>Error: {error}</p>}
// //       {latitude && longitude && (
// //         <div>
// //           <p>Latitude: {latitude}</p>
// //           <p>Longitude: {longitude}</p>
// //           <p>Address: {address}</p>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };
// // export default LocationTracker;
// import React, { useState, useEffect } from 'react';
// const LocationCoordinates = () => {
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);
//   useEffect(() => {
//     const address = "16 Water St S, St Marys, ON, CAnav";
//     const API_KEY = "AIzaSyBwWrA2Zwyk_XXqBzGdp7B1Bt9mXCLG5CA";
//     const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${API_KEY}`;
//     fetch(url)
//       .then(response => response.json())
//       .then(data => {
//         if (data.results.length > 0) {
//           const location = data.results[0].geometry.location;
//           setLatitude(location.lat);
//           setLongitude(location.lng);
//         } else {
//           console.error("No results found for the address.");
//         }
//       })
//       .catch(error => {
//         console.error("Error fetching coordinates:", error);
//       });
//   }, []);
//   return (
//     <div>
//       {latitude && longitude ? (
//         <div>
//           <p>Latitude: {latitude}</p>
//           <p>Longitude: {longitude}</p>
//         </div>
//       ) : (
//         <p>Loading coordinates...</p>
//       )}
//     </div>
//   );
// };
// export default LocationCoordinates;
import React, { useState } from 'react';
function PostPage() {
  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const handleShareClick = () => {
    // Handle copying the link to the clipboard
    navigator.clipboard.writeText(window.location.href);
    // Open the sharing modal
    setIsSharingModalOpen(true);
  };
  const handlePlatformShare = (platform) => {
    // Implement sharing logic for the selected platform
    // For example, using a custom sharing URL:
    const sharingUrl = `https://www.example.com/share?url=${encodeURIComponent(window.location.href)}`;
    window.open(sharingUrl, '_blank');
  };
  return (
    <div>
      {/* Post content */}
      <button onClick={handleShareClick}>Share</button>
      {isSharingModalOpen && (
        <div className="sharing-modal">
          <button onClick={() => handlePlatformShare('facebook')}>Facebook</button>
          <button onClick={() => handlePlatformShare('twitter')}>Twitter</button>
          <button onClick={() => handlePlatformShare('linkedin')}>LinkedIn</button>
        </div>
      )}
    </div>
    // https://www.instagram.com/p/C_9lBichGxK/?utm_source=ig_web_copy_link
  );
}
export default PostPage;
