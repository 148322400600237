import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineMore, AiOutlineLike, AiFillLike } from "react-icons/ai";
import {
  FaRegBookmark,
  FaRegCommentDots,
  FaFacebookF,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { PiShareFatThin } from "react-icons/pi";
import { Dropdown, Menu, Modal, Input, Button, message, Skeleton } from "antd";
import moment from "moment";
import DummyUser from "../../../assest/dunmmyuser.png";
import Nocomment from "../../../assest/nocomments.webp";
import { useParams } from "react-router-dom";
import { decode } from "base-64";
import { useDispatch } from "react-redux";
import { fetchfeed } from "../../../redux/slices/feedSlice";
import { fetchSelfProfile } from "../../../redux/slices/userSlice";
import {
  ALlPost,
  GetPostComments,
  PostLike,
  PostPostComment,
  PostReport,
  SavePost,
} from "../../../apis/api";
import NodataImage from "../../../assest/nodata1.jpg";
import LikeImage from "../../../assest/like.png";
import { imageurl, videourl } from "../../../apis/host";
import "./Post.scss";
import { Form } from "react-bootstrap";
const BeauticianPost = ({ type, profileImage, profileName }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportPostId, setReportPostId] = useState(null);
  const [shareModalVisible, setShareModalVisible] = useState(false); // New share modal state
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedPostComments, setSelectedReelComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [selectedPostId, setSelectedPostId] = useState(null);
  const Token = localStorage.getItem("glamlinkToken");
  const showAllPost = useCallback(() => {
    if (id && Token) {
      const decodedId = decode(id);
      ALlPost(decodedId, Token)
        .then((res) => {
          setData(res?.data?.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
          setLoading(false);
        });
    }
  }, [id, Token]);
  useEffect(() => {
    showAllPost();
  }, [showAllPost]);
  const handlePostLike = useCallback(
    (postid) => {
      PostLike(postid, Token)
        .then((res) => {
          message.success(res?.data?.message);
          dispatch(fetchfeed(Token));
          dispatch(fetchSelfProfile(Token));
        })
        .catch((e) => {
          console.log(e, "error");
        });
    },
    [Token, dispatch]
  );
  useEffect(() => {
    if (Token) {
      dispatch(fetchfeed(Token));
      dispatch(fetchSelfProfile(Token));
    }
  }, [Token, dispatch]);
  const handleMenuClick = useCallback(({ key }) => {
    if (key === "reportpost") {
      setModalVisible(true);
    }
  }, []);
  const handleReportPost = useCallback(() => {
    const formData = { post_id: reportPostId, reason: reportReason };
    PostReport(formData, Token)
      .then((res) => {
        message.success(res.data.message);
        setModalVisible(false);
        setReportReason("");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [reportPostId, reportReason, Token]);
  const handleShowComments = useCallback(
    (post_id) => {
      setSelectedPostId(post_id);
      GetPostComments(Token, post_id)
        .then((response) => {
          setSelectedReelComments(response.data.data);
          setShowCommentsModal(true);
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
        });
    },
    [Token]
  );
  const handlePostComment = useCallback(() => {
    if (!newComment.trim()) return;
    const formData = { post_id: selectedPostId, comment: newComment };
    PostPostComment(formData, Token)
      .then(() => {
        // Fetch the updated comments after posting the new comment
        GetPostComments(Token, selectedPostId)
          .then((response) => {
            setSelectedReelComments(response.data.data); // Update comments
            setNewComment(""); // Reset the comment input field
          })
          .catch((error) => {
            console.error("Error fetching comments:", error);
          });
        // Re-fetch the feed to update post comments count, etc.
        dispatch(fetchfeed(Token));
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  }, [newComment, selectedPostId, Token, dispatch]);
  const handleSavePost = useCallback(
    (post_id) => {
      const formData = { post_id: post_id };
      SavePost(formData, Token)
        .then((res) => {
          // console.log(res, "response");
          message.success(res.data.message);
        })
        .catch((e) => {
          console.log(e, "error");
        });
    },
    [Token]
  );
  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="reportpost">Report Post</Menu.Item>
      </Menu>
    ),
    [handleMenuClick]
  );
  const handleSharePost = useCallback(
    (postId, beauticianId, beauticianName) => {
      console.log(postId, "postId");
      const encodedBeauticianName = encodeURIComponent(beauticianName); // URL-encode the name
      const url = `https://user.glamlink.net/beautician-profile/post-share${encodedBeauticianName}/${beauticianId}/${postId}`;
      setShareModalVisible(true);
      setShareOptions([
        {
          icon: <FaFacebookF />,
          link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          label: "Facebook",
        },
        {
          icon: <FaTwitter />,
          link: `https://twitter.com/share?url=${url}`,
          label: "Twitter",
        },
        {
          icon: <FaWhatsapp />,
          link: `https://api.whatsapp.com/send?text=${url}`,
          label: "WhatsApp",
        },
      ]);
    },
    []
  );
  const [shareOptions, setShareOptions] = useState([]);
  if (loading) {
    return (
      <div className="Post">
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
    );
  }
  return (
    <div className="Post">
      {data?.length === 0 ? (
        <img src={NodataImage} alt="No data Found" />
      ) : (
        data?.map((item, index) => (
          <div className="Post_inner" key={index}>
            <div className="top">
              <div className="top_inner">
                <div>
                  <img
                    src={`${imageurl}${profileImage}`}
                    alt=""
                    className="profileimage"
                  />
                </div>
                <div>
                  <h6>{profileName}</h6>
                  <h6 className="hours">
                    {moment(item?.created_at).fromNow()}
                  </h6>
                </div>
              </div>
              {/* {type !== "self" && (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <AiOutlineMore
                    fontSize={30}
                    style={{ cursor: "pointer" }}
                    onClick={() => setReportPostId(item.id)}
                  />
                </Dropdown>
              )} */}
            </div>
            {item?.attachements?.length !== 0 && (
              <div className="MediaSection">
                {item.attachements[0].file_type === "Video" ? (
                  <video controls className="post_video">
                    <source
                      src={`${videourl}${item.attachements[0].file_name}`}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    src={
                      item.attachements[0].file_name.startsWith("https:")
                        ? item.attachements[0].file_name
                        : `${imageurl}${item.attachements[0].file_name}`
                    }
                    alt="Post Media"
                    className="post_img"
                  />
                )}
              </div>
            )}
            <div className="contentSection">
              <p>{item?.content}</p>
            </div>
            <div className="media">
              <div className="hit_media_section">
                <div className="Like">
                  <h6>
                    <img src={LikeImage} alt="like" width={20} height={20} />{" "}
                    {item?.likes_count}
                  </h6>
                </div>
                <div className="Comment">
                  <h6>{item?.comment_count} Comment</h6>
                </div>
              </div>
              <div className="socilmediaSection">
                <div className="Like" onClick={() => handlePostLike(item.id)}>
                  {item?.likes?.length === 0 ? (
                    <AiOutlineLike />
                  ) : (
                    <AiFillLike color="blue" />
                  )}
                  <h6>Like</h6>
                </div>
                <div
                  className="Comment"
                  onClick={() => handleShowComments(item.id)}
                >
                  <FaRegCommentDots />
                  <h6>Comment</h6>
                </div>
                <div
                  className="Share"
                  onClick={() => handleSharePost(item.id, id, profileName)}
                >
                  <PiShareFatThin />
                  <h6>Share</h6>
                </div>
                <div className="Save" onClick={() => handleSavePost(item.id)}>
                  <FaRegBookmark />
                  <h6>Save</h6>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {selectedPostComments?.length === 0 ? (
        <Modal
          title="Comments"
          visible={showCommentsModal}
          onCancel={() => setShowCommentsModal(false)}
          footer={null}
        >
          <img src={Nocomment} alt="no comment " />
          <Form>
            <Form.Group controlId="commentInput">
              <Form.Control
                type="text"
                placeholder="Write Comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Group>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Button
                onClick={handlePostComment}
                style={{ margin: "20px 0px", width: "40%" }}
                disabled={!newComment}
              >
                Post
              </Button>
            </div>
          </Form>
        </Modal>
      ) : (
        <Modal
          title="Comments"
          visible={showCommentsModal}
          onCancel={() => setShowCommentsModal(false)}
          footer={null}
        >
          {selectedPostComments.map((comment, index) => (
            <div className="getComment" key={index}>
              <div className="innercomment">
                <img
                  // src={
                  //   comment?.commented_by?.user_attachments.length === 0
                  //     ? DummyUser
                  //     : `${imageurl}${comment?.commented_by?.user_attachments[0]?.file_name}`
                  // }
                  src={
                    comment?.commented_by?.user_attachments?.length === 0
                      ? DummyUser
                      : comment?.commented_by?.user_attachments[0]?.file_name?.includes(
                          "https:"
                        )
                      ? comment?.commented_by.user_attachments[0]?.file_name
                      : `${imageurl}${comment?.commented_by?.user_attachments[0]?.file_name}`
                  }
                  alt=""
                  className="userimg"
                />
                <div>
                  <h6>{comment?.commented_by?.user_profile?.name}</h6>
                  <small className="text-muted">
                    {moment(comment?.created_at).fromNow()}
                  </small>
                  <p>{comment?.comment}</p>
                </div>
              </div>
            </div>
          ))}
          <Form>
            <Form.Group controlId="commentInput">
              <Form.Control
                type="text"
                placeholder="Write Comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Group>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Button
                onClick={handlePostComment}
                style={{ margin: "20px 0px", width: "40%" }}
                disabled={!newComment}
              >
                Post
              </Button>
            </div>
          </Form>
        </Modal>
      )}
      <Modal
        title="Report Post"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleReportPost}
      >
        <Input.TextArea
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
          placeholder="Please enter a reason"
        />
      </Modal>
      {/* Share modal */}
      <Modal
        title="Share Post"
        visible={shareModalVisible}
        onCancel={() => setShareModalVisible(false)}
        footer={null}
      >
        <div className="shareOptions">
          {shareOptions.map((option, index) => (
            <Button
              key={index}
              type="link"
              href={option.link}
              target="_blank"
              icon={option.icon}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </Modal>
    </div>
  );
};
export default BeauticianPost;
