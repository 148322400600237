import React from "react";
import { Container } from "react-bootstrap";
import { Form, Input, Button, message, Tooltip } from "antd";
import "./EditProfile.scss";
import { UpdateUserNameApi } from "../../apis/api";
import { useDispatch } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";

const UploadUserName = ({ handleClosemodal ,usename}) => {
  const Token = localStorage.getItem("glamlinkToken");
  const [form] = Form.useForm(); // Create form instance
const dispatch =useDispatch()
  const onFinish = (values) => {

    UpdateUserNameApi(values, Token)
      .then((res) => {
        message.success(res?.data?.message);
    dispatch(fetchSelfProfile(Token));

        handleClosemodal();
      })
      .catch((error) => {
        message.error(error.response?.data?.message);

        console.log("Error updating profile:", error.response?.data?.message);
      });
  };

  return (
    <Container className="editProfile">
      <div className="section1">
        <h4>Edit User Name</h4>
      </div>

      <Form
        form={form}
        id="editProfileForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <Tooltip title="Examples: john_doe, jane.doe, user_name, user.name">
              <span>User Name</span>
            </Tooltip>
          }
          name="user_name"
          initialValue={usename}
          rules={[
            {
              required: true,
              message: "Please enter your username",
            },
            {
              pattern: /^[a-z]([a-z._]*[a-z_])?$/,
              message: "Username must start with a letter, can include lowercase letters, full stops, and underscores, and cannot end with a full stop",
            },
          ]}
        >
          <Input placeholder="Enter User Name" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="button_theme">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default UploadUserName;
