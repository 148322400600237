import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { decode } from "base-64";
import { fetchBeauticianProfile } from "../../redux/slices/beauticianSlice";
import { Form, Input, Select } from "antd";
import TimeSlot from "../../TimeSlot/TimeSlot";
import Login from "../AuthLayout/Login/Login";
import Layout from "../Layout/Layout";
const BookAppointment = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const [userId, setUserId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [appointmentReason, setAppointmentReason] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    if (id && Token) {
      const decodedId = decode(id);
      setUserId(decodedId);
      dispatch(fetchBeauticianProfile({ userId: decodedId, Token }));
    }
    else {
      navigate("/login");
    }
  }, [id, Token, dispatch,navigate]);
  const userData = useSelector(
    (state) => state?.beauticianprofile?.profile?.data
  );
  const handleServiceChange = (value) => {
    setSelectedService(value);
  };
  const handleReasonChange = (e) => {
    setAppointmentReason(e.target.value);
  };
  return (
    <>
 
        <Layout data={userData} type="beautician">
          <div className="Booking_appointment">
            <Form layout="vertical">
              <Form.Item label="Select Service">
                <Select
                  id="treatmentType"
                  placeholder="Select a treatment"
                  size="large"
                  style={{ width: "100%" }}
                  onChange={handleServiceChange}
                >
                  {userData?.services.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Add Your Reason">
                <div className="textAreaContainer">
                  <TextArea
                    id="appointmentReason"
                    placeholder="Appointment Reason"
                    rows={4}
                    onChange={handleReasonChange}
                  />
                </div>
              </Form.Item>
            </Form>
            {appointmentReason && (
              <TimeSlot
                beauticianId={id}
                selectedService={selectedService}
                appointmentReason={appointmentReason}
              />
            )}
          </div>
        </Layout>
     
    </>
  );
};
export default BookAppointment;
