import React, { useEffect, useState } from "react";
import { GetNotificationApi } from "../../apis/api";
import "./Notification.scss"
import { Container } from "react-bootstrap";
const Notification = () => {
  const [notification, setNotification] = useState();

  const Token = localStorage.getItem("glamlinkToken");

  useEffect(() => {
    GetNotificationApi(Token)
      .then((res) => {
        setNotification(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [Token]);
  return (
    <Container className="Notification">
      <ul class="list-group">
        {notification?.map((item, index) => {
          return (
            <li class="list-group-item" key={index}>
              {item?.message}
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default Notification;
