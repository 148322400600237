import React, { useEffect, useState } from "react";
import ProfilePage from "../Profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
const SelfProfile = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  const profile = useSelector((state) => state?.user?.profile?.data);
  const [posttype, setPostType] = useState("profile");
  return (
    <div>
      <ProfilePage
        type="self"
        data={profile}
        posttype={posttype}
        setPostType={setPostType}
        passby="profile"
      />
    </div>
  );
};
export default SelfProfile;
