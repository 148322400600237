import React, { useEffect, useState } from "react";
import "./ShowPost.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import Layout from "../Layout/Layout";
import Posts from "../Profile/Posts/Posts";
import { ALlPostSelf } from "../../apis/api";
const ShowPost = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const [post, setPost] = useState();
  useEffect(() => {
    ALlPostSelf(Token)
      .then((res) => {
        setPost(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [ Token]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="ShowPost">
      <Layout type="self">
        <Posts
          type="self"
          data={post}
          // profileName={profile?.user_profile?.name}
          // profileImage={profile?.user_attachments[0]?.file_name}
          passby="profile"
        />
      </Layout>
    </div>
  );
};
export default ShowPost;
