import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Layout.scss";
import ProfileCard from "../Profile/ProfileCard/ProfileCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { fetchBeauticianProfile } from "../../redux/slices/beauticianSlice";
import { decode } from "base-64";
const Layout = ({ children, data, type, setPostType, posttype, passby }) => {
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id,"id in parma")
  useEffect(() => {
    if (!data) {
      if (Token) {
        if (type === "self") {
          console.log("seldfff")
          dispatch(fetchSelfProfile(Token));
        } else if (type === "beautician" && id) {
          console.log("beauttiiii")
          const decodedId = decode(id);
          console.log(decodedId,"decodeddd id ")
          dispatch(fetchBeauticianProfile({ userId: decodedId, Token }));
        }
      } else {
        navigate("/login");
      }
    }
  }, [Token, type, id, data, dispatch, navigate]);
  const profile = useSelector((state) => state.user?.profile?.data);
  const userData = useSelector((state) => state.beauticianprofile?.profile?.data);
  return (
    <div className="Layout">
      <Container>
        <Row>
          <Col sm={3} className="left">
            <ProfileCard
              data={data || (type === "self" ? profile : userData)}
              type={type}
              setPostType={setPostType}
              posttype={posttype}
              passby={passby}
            />
          </Col>
          <Col sm={9} className="right">
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Layout;
