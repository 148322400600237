// slices/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetSelfProfileAPi } from '../../apis/api';
export const fetchSelfProfile = createAsyncThunk(
  'user/fetchSelfProfile',
  async (Token) => {
    const response = await GetSelfProfileAPi(Token);
    return response.data;
  }
);
const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: {},
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelfProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSelfProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchSelfProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});
export default userSlice.reducer;
