import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllReels } from "../redux/slices/reelSlice";
import moment from "moment";
import { FaCommentDots, FaHeart, FaShare } from "react-icons/fa";
import { reelLike, PostComment, GetReelComments } from "../apis/api";
import { imageurl, videourl } from "../apis/host";
import { Container, Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DummyUser from "../assest/dunmmyuser.png";
import Nocomment from "../assest/nocomments.webp";
import "./AllReels.scss";
const AllReels = () => {
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedReelComments, setSelectedReelComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [selectedReelId, setSelectedReelId] = useState(null);
  const dispatch = useDispatch();
  const Token = localStorage.getItem("glamlinkToken");
  const navigate = useNavigate();
  useEffect(() => {
    if (Token) {
      dispatch(fetchAllReels(Token));
    } else {
      navigate("/login"); // Redirect to login page if token is not found
    }
  }, [dispatch, Token, navigate]);
  const reels = useSelector((state) => state?.reel?.reel?.data);
  const toggleVideoPlayback = (index) => {
    if (playingVideoIndex === index) {
      setPlayingVideoIndex(null);
    } else {
      setPlayingVideoIndex(index);
    }
  };
  const handleReelLike = (reel_id) => {
    reelLike(reel_id, Token)
      .then(() => {
        dispatch(fetchAllReels(Token));
      })
      .catch((e) => {
        console.error("Error liking reel:", e);
      });
  };
  const toggleDescription = (index) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const handleShowComments = (reel_id) => {
    console.log(reel_id, "reeeee");
    setShowCommentsModal(true);
    setSelectedReelId(reel_id);
    GetReelComments(Token, reel_id)
      .then((response) => {
        console.log(response, "jbhj gbh");
        setSelectedReelComments(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  };
  const handlePostComment = () => {
    if (!newComment.trim()) return;
    const formData = { reel_id: selectedReelId, comment: newComment };
    PostComment(formData, Token)
      .then(() => {
        return GetReelComments(Token, selectedReelId);
      })
      .then((response) => {
        setSelectedReelComments(response.data.data);
        setNewComment("");
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  };
  const handleCloseCommentsModal = () => {
    setShowCommentsModal(false);
    setSelectedReelComments([]);
    setSelectedReelId(null);
  };
  return (
    <Container className="Showreel">
      <div className="app__videos">
        {reels?.map((item, index) => {
          const isExpanded = expandedDescriptions[index];
          const description = item?.description || "";
          const truncatedDescription =
            description.length > 100
              ? description.slice(0, 100) + "..."
              : description;
          return (
            <div className="videoContainer" key={index}>
              <video
                onClick={() => toggleVideoPlayback(index)}
                className="videoCard__player"
                src={`${videourl}${item?.file_name}`}
                alt="IG reel video"
                width="100%"
                loop
                controls={playingVideoIndex === index}
              />
              <div className="videoButtons">
                <div
                  className="likeButton"
                  onClick={() => handleReelLike(item.id)}
                >
                  <FaHeart
                    color={item?.reel_likes?.length > 0 ? "red" : "black"}
                    size={30}
                  />
                  <h6>{item?.likes_count}</h6>
                </div>
                <div
                  className="commentButton"
                  onClick={() => handleShowComments(item.id)}
                  style={{ cursor: "pointer" }}
                >
                  <FaCommentDots color="black" size={30} />
                  <h6>{item?.comment_count}</h6>
                </div>
                <div className="shareButton">
                  <FaShare color="black" size={30} />
                  <h6>Share</h6>
                </div>
              </div>
              <div className="videofooter">
                <h6>
                  {isExpanded ? description : truncatedDescription}{" "}
                  {description.length > 100 && (
                    <span
                      onClick={() => toggleDescription(index)}
                      className="toggleDescription"
                    >
                      {isExpanded ? "See less" : "See more"}
                    </span>
                  )}
                </h6>
                <div className="namedate">
                  <h6>{item?.reel_user?.user_profile?.name}</h6>
                  <span>{moment(item?.created_at).format("D MMMM YYYY")}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* Comments Modal */}
      {selectedReelComments.length === 0 ? (
        <Modal
          show={showCommentsModal}
          onHide={handleCloseCommentsModal}
          centered
        >
          {" "}
          <Modal.Header closeButton>
            <Modal.Title>Comments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={Nocomment} alt="no comment " />
            <Form.Group controlId="commentInput">
              <Form.Label>Add a comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Write a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={showCommentsModal}
          onHide={handleCloseCommentsModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Comments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedReelComments?.map((comment, index) => (
              <div className="getComment" key={index}>
                {comment?.comment && (
                  <div className="innercomment">
                    <img
                      src={
                        comment?.reel_commented_by?.user_attachments.length ===
                        0
                          ? DummyUser
                          : `${imageurl}${comment?.reel_commented_by?.user_attachments[0]?.file_name}`
                      }
                      alt=""
                      className="userimg"
                    />
                    <div>
                      <h6>
                        {comment?.reel_commented_by?.user_profile?.name}{" "}
                        <small className="text-muted">
                          {moment(comment?.created_at).fromNow()}
                        </small>
                      </h6>
                      <p>{comment?.comment}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <Form.Group controlId="commentInput">
              <Form.Label>Add a comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Write a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCommentsModal}>
              Close
            </Button>
            <Button
              variant="secondary"
              onClick={handlePostComment}
              disabled={!newComment.trim()}
            >
              Post Comment
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};
export default AllReels;
