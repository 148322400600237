import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "base-64";
import { fetchBeauticianProfile } from "../../redux/slices/beauticianSlice";
import ProfileBeautician from "../Profile/ProfileBeautician";
const BeauticianProfile = () => {
  const { id } = useParams();
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (id && Token) {
      const decodedId = decode(id);
      // setUserId(decodedId);
      dispatch(fetchBeauticianProfile({ userId: decodedId, Token }));
    } else {
      navigate("/login");
    }
  }, [id, Token, dispatch, navigate]);
  const userData = useSelector(
    (state) => state?.beauticianprofile?.profile?.data
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <ProfileBeautician data={userData} type="beautician" />
    </div>
  );
};
export default BeauticianProfile;
