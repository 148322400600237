import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Services from "./Services/Services";
import "./Profile.scss";
import Posts from "./Posts/Posts";
import Layout from "../Layout/Layout";
import { FaArrowRight } from "react-icons/fa6";
import GridPhoto from "./GridPhoto/GridPhoto";
import GridReels from "./GridReels/GridReels";
import { useNavigate } from "react-router-dom";
import { GetSavePost } from "../../apis/api";
import { Skeleton, Button } from "antd";
const ProfilePage = ({ data, type, posttype, setPostType, passby }) => {
  const Token = localStorage.getItem("glamlinkToken");
  const roleid = localStorage.getItem("role_id");
  const navigate = useNavigate();
  const [savePost, setSavePost] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    GetSavePost(Token)
      .then((res) => {
        setSavePost(res?.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e, "error");
        setLoading(false);
      });
  }, [Token]);
  console.log(data);
  return (
    <Layout
      // data={data}
      type={type}
      posttype={posttype}
      setPostType={setPostType}
      passby={passby}
    >
      {loading ? (
        <Skeleton active />
      ) : posttype === "profile" ? (
        <div className="profileDetails">
          <p className="text">{data?.user_profile?.about==="undefined"?"---":data?.user_profile?.about}</p>
          {roleid === "7" && type === "self" && (
            <Services data={data?.services} type={type} />
          )}
          <GridPhoto data={data?.user_albums} type={type} />
          <GridReels data={data?.user_reels} type={type} />
          <Posts
            data={data?.posts}
            type={type}
            profileName={data?.user_profile?.name}
            profileImage={
              data?.user_attachments.length !== 0
                ? data?.user_attachments[0]?.file_name
                : null
            }
            passby={passby}
          />
          <div className="showAllPosts">
            <span
              className="showAllPostsText"
              onClick={() => navigate("/showpost")}
            >
              Show all Posts
            </span>
            <FaArrowRight className="showAllPostsIcon" />
          </div>
        </div>
      ) : (
        <div className="savepost">
          <Posts
            data={savePost}
            type="savepost"
            profileName={data?.user_profile?.name}
            profileImage={
              data?.user_attachments.length !== 0
                ? data?.user_attachments[0]?.file_name
                : null
            }
          />
        </div>
      )}
    </Layout>
  );
};
ProfilePage.propTypes = {
  data: PropTypes.shape({
    user_profile: PropTypes.shape({
      about: PropTypes.string,
      name: PropTypes.string,
    }),
    services: PropTypes.array,
    user_albums: PropTypes.array,
    user_reels: PropTypes.array,
    posts: PropTypes.array,
    user_attachments: PropTypes.arrayOf(
      PropTypes.shape({
        file_name: PropTypes.string,
      })
    ),
  }).isRequired,
  type: PropTypes.string.isRequired,
  posttype: PropTypes.string,
  setPostType: PropTypes.func,
  passby: PropTypes.string,
};
export default ProfilePage;
