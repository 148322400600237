import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Explore from "./Pages/Explore/Explore";
import EditProfile from "./Components/EditProfile/EditProfile";
import SharePost from "./Components/SharePost/SharePost";
import AvailabilityContainer from "./Components/Availability/Availability";
import CounslorList from "./Components/CounslorList/CounslorList";
import BeauticianProfile from "./Components/BeauticianProfile/BeauticianProfile";
import SelfProfile from "./Components/SelfProfile/SelfProfile";
import Try from "./Components/Profile/try";
import Verify from "./Components/Verify/Verify";
import AllCounslorList from "./Components/CounslorList/AllCounslorList";
import AlbumPage from "./Components/albumPage/albumPage";
import AuthLayout from "./Components/AuthLayout/AuthLayout";
import Login from "./Components/AuthLayout/Login/Login";
import { SignUp } from "./Components/AuthLayout/SignUp/SignUp";
import CreateAccount from "./Components/AuthLayout/CreateAccount/CreateAccount";
import ShareReel from "./Components/ShareReel/ShareReel";
import AllReels from "./Components/AllReels";
import Showreel from "./Components/ShowReel/ShowReel";
import BookAppointment from "./Components/BookAppointment/BookAppointment";
import Inbox from "./Components/Inbox/Inbox";
import MessageInbox from "./Components/Inbox/MesaageInbox";
import ForgotPassword from "./Components/AuthLayout/ForgotPassword/ForgotPassword";
import BookingsforUser from "./Pages/Bookings/BookingsforUser";
import BookingsforBeautician from "./Pages/Bookings/BookingsforBeautician";
import ShowPost from "./Components/ShowPost/ShowPost";
import Notification from "./Components/Notification/Notification";
import PostPage from "./Components/Profile/try";
import SharedPostPage from "./Components/Profile/Posts/PostShare";
const WebRouter = () => {
  const roleid = localStorage.getItem("role_id");
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<CounslorList />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route
          exact
          path="/signup"
          element={
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          }
        />
        <Route
          exact
          path="/createaccount"
          element={
            <AuthLayout>
              <CreateAccount />
            </AuthLayout>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <AuthLayout>
              <ForgotPassword />
            </AuthLayout>
          }
        />
        {/* <Route exact path="/account" element={<Account />} /> */}
        <Route
          exact
          path="/book-appointment/:name/:id"
          element={<BookAppointment />}
        />
        <Route exact path="/edit-profile" element={<EditProfile />} />
        <Route exact path="/sharepost" element={<SharePost />} />
        <Route path="/beautician-profile/postshare" element={<SharedPostPage />} />
        <Route exact path="/sharereel" element={<ShareReel />} />
        <Route exact path="/try" element={<PostPage />} />
        <Route exact path="/verify-account" element={<Verify />} />
        <Route exact path="/showpost" element={<ShowPost />} />
        <Route exact path="/notifications" element={<Notification />} />
        {/* <Route exact path="/profile" element={<ProfilePage />} /> */}
        <Route
          exact
          path="/manage-availability"
          element={<AvailabilityContainer />}
        />
        <Route exact path="/profile" element={<SelfProfile />} />
        <Route
          exact
          path="/profile/album-section/:title/:id?"
          element={<AlbumPage />}
        />
        <Route
          exact
          path="/beautician-profile/:name/:id"
          element={<BeauticianProfile />}
        />
        <Route exact path="/explore" element={<CounslorList />} />
        <Route
          exact
          path="/allrofessionalslist"
          element={<AllCounslorList />}
        />
        <Route exact path="/showreel/reel/:url" element={<Showreel />} />
        <Route exact path="/showreel" element={<AllReels />} />
        <Route exact path="/home" element={<Explore />} />
        <Route exact path="/inbox" element={<Inbox />} />
        <Route exact path="/inbox/:id" element={<MessageInbox />} />
        <Route
          exact
          path="/bookings"
          element={
            roleid === "7" ? <BookingsforBeautician /> : <BookingsforUser />
          }
        />
      </Routes>
    </div>
  );
};
export default WebRouter;
