import React, { useEffect, useState } from "react";
import "./CounslorList.scss";
import { Container } from "react-bootstrap";
import { encode } from "base-64";
import DummyUser from "../../assest/dunmmyuser.png";
import { CounslorListApi, NearByCounslorListApi } from "../../apis/api";
import { useNavigate } from "react-router-dom";
import { imageurl } from "../../apis/host";
import { IoMdArrowForward } from "react-icons/io";
import Login from "../AuthLayout/Login/Login";
import AuthLayout from "../AuthLayout/AuthLayout";

const CounslorList = () => {
  const [counslordata, setCounslordata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [data1, setData1] = useState([]);
  const Token = localStorage.getItem("glamlinkToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (Token) {
      setLoading(true);
      CounslorListApi(Token)
        .then((res) => {
          setCounslordata(res.data.data);
          setLoading(false);
        })
        .catch((e) => {
          console.error("Error fetching counselor list:", e);
          setLoading(false);
        });
    }
    else {
      navigate("/login");
    }
  }, [Token,navigate]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (location.latitude !== null && location.longitude !== null && Token) {
      const formData = {
        latitude: location.latitude,
        longitude: location.longitude,
      };

      NearByCounslorListApi(formData, Token)
        .then((res) => setData1(res?.data?.data || []))
        .catch((e) => console.error("Error fetching nearby counselors:", e));
    }
  }, [location, Token]);

  const showPosition = (position) => {
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
    setError(null);
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setError("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        setError("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        setError("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
      default:
        setError("An unknown error occurred.");
        break;
    }
  };

  const handleProfileClick = (name, id) => {
    navigate(`/beautician-profile/${name}/${encode(id)}`);
  };

  const handleRidirection = () => {
    setShowAll(!showAll);
  };

  const renderSkeletonLoader = () => {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          <div className="skeleton-card" key={index}>
            <div className="skeleton-profile-photo"></div>
            <div className="skeleton-text">
              <div className="skeleton-title"></div>
              <div className="skeleton-subtitle"></div>
            </div>
          </div>
        ))}
      </>
    );
  };


  return (
    <div className="counslor">
      <Container className="Beauty_main">
        {data1.length > 0 && (
          <div className="main_counslor">
            <div className="top_heading">
              <h1>Professionals near me</h1>
              {data1.length === 4 && (
                <div className="show" onClick={handleRidirection}>
                  {showAll ? "show less" : "show all"}
                  <IoMdArrowForward />
                </div>
              )}
            </div>
            {loading
              ? renderSkeletonLoader()
              : (showAll ? data1 : data1.slice(0, 3)).map((item, index) => (
                  <div
                    className="card"
                    key={index}
                    onClick={() =>
                      handleProfileClick(item.user_profile?.name, item.id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body">
                      <div className="top">
                        <div>
                          <img
                            src={
                              item.user_attachments?.length === 0
                                ? DummyUser
                                : item.user_attachments[0]?.file_name?.includes(
                                    "https:"
                                  )
                                ? item.user_attachments[0]?.file_name
                                : `${imageurl}${item.user_attachments[0]?.file_name}`
                            }
                            alt="Profile"
                            className="profilePhoto"
                            width={80}
                            height={80}
                          />
                        </div>
                        <div>
                          <h5 className="card-title">
                            {item.user_profile?.name}
                          </h5>
                          <h6>{item.email}</h6>
                        </div>
                      </div>
                      <div className="aboutus">
                        {item.user_profile?.about}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}
        <div className="main_counslor">
          <div className="top_heading">
            <h1>Top Professionals</h1>
            <div className="show" onClick={handleRidirection}>
              {showAll ? "show less" : "show all"}
              <IoMdArrowForward />
            </div>
          </div>
          {loading
            ? renderSkeletonLoader()
            : (showAll ? counslordata : counslordata.slice(0, 3)).map(
                (item, index) => (
                  <div
                    className="card"
                    key={index}
                    onClick={() =>
                      handleProfileClick(item.user_profile?.name, item.id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body">
                      <div className="top">
                        <div>
                          <img
                            src={
                              item.user_attachments?.length === 0
                                ? DummyUser
                                : item?.user_attachments[0]?.file_name?.includes(
                                    "https:"
                                  )
                                ? item.user_attachments[0]?.file_name
                                : `${imageurl}${item.user_attachments[0]?.file_name}`
                            }
                            alt="Profile"
                            className="profilePhoto"
                            width={80}
                            height={80}
                          />
                        </div>
                        <div>
                          <h5 className="card-title">
                            {item.user_profile?.name}
                          </h5>
                          <h6>{item.email}</h6>
                        </div>
                      </div>
                      <div className="aboutus">
                        {item.user_profile?.about}
                      </div>
                    </div>
                  </div>
                )
              )}
        </div>
      </Container>
    </div>
  );
};

export default CounslorList;
