import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ALlPost } from "../../../apis/api"; // Adjust the path if needed
import { imageurl } from "../../../apis/host";
import { Skeleton } from "antd";
import NodataImage from "../../../assest/nodata1.jpg";
// import "./SharedPostPage.scss";
const SharedPostPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  console.log(query,"addd")
  const postId = query.get("postId");
  const beauticianId = query.get("beauticianId");
  const beauticianName = query.get("beauticianName");
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (beauticianId) {
      ALlPost(beauticianId)
        .then((res) => {
          const postData = res?.data?.data?.find((item) => item.id === postId);
          setPost(postData);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching shared post:", error);
          setLoading(false);
        });
    }
  }, [beauticianId, postId]);
  if (loading) {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  }
  if (!post) {
    return <img src={NodataImage} alt="No post found" />;
  }
  return (
    <div className="SharedPostPage">
      <div className="Post_inner">
        <div className="PostHeader">
          <img src={post.profileImage ? imageurl + post.profileImage : ""} alt={post.profileName} />
          <div className="PostDetails">
            <h5>{post.profileName}</h5>
            <span>{post.createdAt}</span>
          </div>
        </div>
        <div className="PostContent">
          <p>{post.description}</p>
          {post.image && <img src={imageurl + post.image} alt="Post content" />}
        </div>
      </div>
    </div>
  );
};
export default SharedPostPage;
