import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import "./Services.scss";

const Services = ({ data }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating data fetching
    if (data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="service">
      <h6 style={{ marginBottom: "15px" }}>Services</h6>
      {loading ? (
        <Skeleton active paragraph={{ rows: 3 }} />
      ) : (
        <>
          {data?.length === 0 && (
            <div className="serviceDetails">
              <p style={{ fontSize: "16px", fontWeight: 400 }}>---</p>
            </div>
          )}
          {data?.map((item, index) => (
            <div className="serviceDetails" key={index}>
              <p style={{ fontSize: "16px", fontWeight: 400 }}>{item?.name}</p>
              <p className="serviceCharge">${item?.price}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Services;
