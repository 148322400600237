import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./SharePost.scss";
import { Input, Upload, message, Button } from "antd";
import {
  PictureOutlined,
  PlusOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { AddPostApi } from "../../apis/api";
import { imageurl } from "../../apis/host";
import ImgCrop from "antd-img-crop";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;
const SharePost = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const [photoFiles, setPhotoFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (Token) {
      dispatch(fetchSelfProfile(Token));
    } else {
      navigate("/login");
    }
  }, [dispatch, Token, navigate]);
  const profile = useSelector((state) => state?.user?.profile?.data);
  const handlePhotoChange = ({ fileList }) => {
    setPhotoFiles(fileList);
  };
  const handleVideoChange = ({ fileList }) => {
    setVideoFiles(fileList);
  };
  const handlePost = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("content", text);
    photoFiles.forEach((file) => {
      formData.append("images", file.originFileObj);
    });
    videoFiles.forEach((file) => {
      formData.append("videos", file.originFileObj);
    });
    AddPostApi(formData, Token)
      .then((response) => {
        message.success("Post added successfully");
        setText("");
        setPhotoFiles([]);
        setVideoFiles([]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding post:", error);
        message.error("Error adding post");
        setLoading(false);
      });
  };
  const uploadButtonPhoto = (
    <div>
      <PictureOutlined />
      <div style={{ marginTop: 8 }}>Add a Photo</div>
    </div>
  );
  const uploadButtonVideo = (
    <div>
      <VideoCameraAddOutlined />
      <div style={{ marginTop: 8 }}>Add a Video</div>
    </div>
  );
  const handleClearData = () => {
    setText("");
    setPhotoFiles([]);
    setVideoFiles([]);
  };
  return (
    <div className="sharePostContainer">
      <Container>
        <div className="top_heading">
          <div>
            <h3>Share Post</h3>
          </div>
          <div>
            <Button className="cancelBtn" onClick={handleClearData}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="postBtn"
              onClick={handlePost}
              loading={loading}
            >
              Post
            </Button>
          </div>
        </div>
        <div className="sharePosts">
          <div className="profile">
            {profile?.user_attachments?.length === 0 ? (
              <img
                src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                width={50}
                height={50}
                alt="Profile"
                className="profilePhoto"
              />
            ) : (
              <img
              src={
                profile?.user_attachments?.length === 0
                  ? "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                  : profile?.user_attachments[0]?.file_name.startsWith("https:")
                  ? profile?.user_attachments[0]?.file_name
                  : `${imageurl}${profile?.user_attachments[0]?.file_name}`
              }
                // src={`${imageurl}${profile?.user_attachments[0]?.file_name}`}
                width={50}
                height={50}
                alt="Profile"
                className="profilePhoto"
              />
            )}
            <div className="name">
              <h5 style={{ fontWeight: 700 }}>{profile?.user_profile?.name}</h5>
            </div>
          </div>
          <div className="text_area">
            <TextArea
              className="noBorderOnFocus border-0"
              rows={5}
              placeholder="What do you want to talk about?"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div className="align-items-center mt-4 custom_imgcard">
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-card"
                  fileList={photoFiles}
                  onChange={handlePhotoChange}
                  beforeUpload={() => false}
                  showUploadList={{ showRemoveIcon: true }}
                >
                  {photoFiles.length < 1 &&
                    videoFiles.length === 0 &&
                    uploadButtonPhoto}
                </Upload>
              </ImgCrop>
              <Upload
                listType="picture-card"
                fileList={videoFiles}
                onChange={handleVideoChange}
                beforeUpload={() => false}
                showUploadList={{ showRemoveIcon: true }}
              >
                {videoFiles.length < 1 &&
                  photoFiles.length === 0 &&
                  uploadButtonVideo}
              </Upload>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default SharePost;
