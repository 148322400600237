import { useEffect, useState } from "react";
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";
// import styles from "./ChatsList.scss"; // Assuming you are using SCSS modules
import { imageurl } from "../../apis/host";
import { Input } from "antd";
import { CiSearch } from "react-icons/ci";
import "./ChatsList.scss";
import DummyUser from "../../assest/dunmmyuser.png";

// const userId = "51";

export default function ChatsList({ onUserClick }) {
  const [chatDetails, setChatDetails] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const query = ref(db, "chats");

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const chatDetailsMap = {};

        Object.keys(data).forEach((chatKey) => {
          const [user1, user2] = chatKey.split("_").map(Number);
          if (user1 === Number(userId) || user2 === Number(userId)) {
            const messages = Object.values(data[chatKey]);
            const lastMessage = messages[messages.length - 1];
            const chatPartnerId = Number(userId) === user1 ? user2 : user1;
            chatDetailsMap[chatPartnerId] = lastMessage;
          }
        });

        setChatDetails(
          Object.entries(chatDetailsMap).map(([id, details]) => ({
            id,
            ...details,
          }))
        );
      }
    });
  }, []);

  const handleUserClick = (id, name, image) => {
    setSelectedChatId(id);
    onUserClick(id, name, image);
  };

  // Filter chat details based on search query
  const filteredChatDetails = chatDetails.filter((item) =>
    item.receiver_name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <div>
      <Input
        placeholder="Search here..."
        prefix={<CiSearch />}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update search query
      />

      <div className="chatListContainer">
        {filteredChatDetails.length > 0 ? (
          filteredChatDetails.map((item, index) => (
            <div
              key={index}
              onClick={() =>
                Number(userId) === Number(item?.receiver)
                  ? handleUserClick(
                      item?.sender,
                      item?.sender_name,
                      item?.sender_profile
                    )
                  : handleUserClick(
                      item?.receiver,
                      item?.receiver_name,
                      item?.receiver_profile
                    )
              }
              className={`userItem ${
                selectedChatId === item.id ? "selected" : ""
              }`}
            >
              <div className="userInfo">
                <div className="user_icon_name">
                  <div>
                    {Number(userId) === Number(item?.receiver) ? (
                      <img
                        // src={`${imageurl}${item?.receiver_profile}`}
                        src={
                          item?.sender_profile === null
                            ? DummyUser
                            : item?.sender_profile?.includes("https:")
                            ? item?.sender_profile
                            : `${imageurl}${item?.sender_profile}`
                        }
                        className="user_image"
                        alt=""
                      />
                    ) : (
                      <img
                        // src={`${imageurl}${item?.receiver_profile}`}
                        src={
                          item?.receiver_profile === null
                            ? DummyUser
                            : item?.receiver_profile?.includes("https:")
                            ? item?.receiver_profile
                            : `${imageurl}${item?.receiver_profile}`
                        }
                        className="user_image"
                        alt=""
                      />
                    )}
                  </div>

                  <div>
                    <div className="userName">
                      {Number(userId) === Number(item?.receiver)
                        ? item?.sender_name
                        : item?.receiver_name}
                    </div>
                    <div className="lastMessage">{item.message}</div>
                  </div>
                </div>
                <div className="message_date">
                  {new Date().toLocaleDateString() ===
                  new Date(item?.timestamp).toLocaleDateString()
                    ? "today"
                    : new Date(
                        new Date().setDate(new Date().getDate() - 1)
                      ).toLocaleDateString() ===
                      new Date(item?.timestamp).toLocaleDateString()
                    ? "yesterday"
                    : new Date(item?.timestamp).toLocaleDateString()}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="noResults">No users found</div>
        )}
      </div>
    </div>
  );
}
