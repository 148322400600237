import React, { useEffect, useState } from "react";
import "./Reviews.scss";
import { Button, Form, Input, Rate, message } from "antd";
import roundImage2 from "../../../assest/Round2.png";
import { AddReview, GetReview, UpdateReview } from "../../../apis/api"; // Add UpdateReview
import { useParams } from "react-router-dom";
import { decode } from "base-64";
import { imageurl } from "../../../apis/host";
import { useDispatch } from "react-redux";
import { FaEdit, FaRegEdit } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import DummyUser from "../../../assest/dunmmyuser.png";
const Reviews = ({ roleid, reviewed }) => {
  const userid = localStorage.getItem("user_id");
  const { id } = useParams();
  const [form] = Form.useForm();
  const Token = localStorage.getItem("glamlinkToken");
  const [review, setReview] = useState([]);
  const [editingReview, setEditingReview] = useState(null); // State for editing review
  const decodedId = decode(id);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const formData = {
      text: values.text,
      counselor_id: decodedId,
      level: values.level?.toString(),
    };
    AddReview(formData, Token)
      .then((res) => {
        message.success("Post Successful");
        form.resetFields();
        GetReviewData();
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const GetReviewData = () => {
    GetReview(decodedId, Token)
      .then((res) => {
        setReview(res?.data?.data?.counselor_reviews);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleEdit = (review) => {
    setEditingReview(review);
    form.setFieldsValue({
      level: review.level,
      text: review.text,
    });
  };
  useEffect(() => {
    GetReviewData();
  }, [decodedId, Token]);
  return (
    <div className="Reviews">
      <h6>Reviews</h6>
      {roleid === 7 && (
        <div className="Main">
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="level" style={{ margin: "0" }}>
              <Rate />
            </Form.Item>
            <Form.Item name="text" className="PostReview">
              <Input
                placeholder="Add a review..."
                className="noBorderOnFocus"
              />
            </Form.Item>
            <Form.Item className="submit">
              <Button htmlType="submit" className="doneBtn">
                {reviewed ? "Update" : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <div className="GetReview">
        {review?.map((item, index) => {
          return (
            <div className="ReviewShow" key={index}>
              <div className="Top">
                <div>
                  <img
                    src={
                      item?.reviewed_by?.user_attachments?.length === 0
                        ? DummyUser
                        : item?.reviewed_by?.user_attachments[0]?.file_name?.includes(
                            "https:"
                          )
                        ? item?.reviewed_by?.user_attachments[0]?.file_name
                        : `${imageurl}${item?.reviewed_by?.user_attachments[0]?.file_name}`
                    }
                    // src={`${imageurl}${item?.reviewed_by?.user_attachments[0]?.file_name}`}
                    alt=""
                    className="img"
                  />
                </div>
                <div>
                  <h6>{item?.reviewed_by?.user_profile?.name}</h6>
                  <div className="rate">
                    <FaStar color="#F2C94C" size={15} />
                    <span>{item?.level}</span>
                  </div>
                </div>
                {item.user_id === parseInt(userid) && (
                  <div>
                    <FaEdit
                      onClick={() => handleEdit(item)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
              <div>
                <p>{item?.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Reviews;
