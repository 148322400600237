import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import "./ProfileCard.scss";
import { imageurl } from "../../../apis/host";
import DummyUser from "../../../assest/dunmmyuser.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeauticianProfile } from "../../../redux/slices/beauticianSlice";
import { followUser } from "../../../redux/slices/followSlice";
import { decode, encode } from "base-64";
import { Dropdown, Input, Menu, Modal, message } from "antd";
import { AiOutlineMore } from "react-icons/ai";
import {
  BlockUser,
  FollowApi,
  RemoveFollowerApi,
  UserReport,
} from "../../../apis/api";
import { fetchFollowers } from "../../../redux/slices/followersSlice";
import { fetchFollowing } from "../../../redux/slices/followingSlice";
import { fetchSelfProfile } from "../../../redux/slices/userSlice";
const ProfileCard = ({ data, type, setPostType, posttype, passby }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportUserId, setReportUserId] = useState(null);
  const [modalAction, setModalAction] = useState("");
  const [followersModalVisible, setFollowersModalVisible] = useState(false);
  const [followingModalVisible, setFollowingModalVisible] = useState(false);
  const { id, name } = useParams();
  const Token = localStorage.getItem("glamlinkToken");
  const [loading, setLoading] = useState(true); // Loading state for skeleton
  const location = useLocation();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (id && Token) {
      const decodedId = decode(id);
      setUserId(decodedId);
    }
  }, [id, Token]);
  const handleFollow = useCallback(() => {
    const formData = { followee_id: userId };
    dispatch(followUser({ formData, Token }))
      .then(() => {
        dispatch(fetchBeauticianProfile({ userId, Token }));
      })
      .catch((error) => console.error("Error following user:", error));
  }, [dispatch, userId, Token]);
  const navigate = useNavigate();
  const path = useMemo(
    () => location.pathname.split("/")[1],
    [location.pathname]
  );
  const handleRedirection = (path) => {
    const routes = {
      profile: `/${path}`,
      inbox: `/${path}/${data?.id}`,
      "manage-availability": `/${path}`,
      "book-appointment": `/${path}/${name}/${id}`,
    };
    navigate(routes[path] || `/${path}`);
  };
  const handleMenuClick = useCallback(({ key }) => {
    if (key === "reportpost") {
      setModalAction("reportUser");
      setModalVisible(true);
    } else if (key === "blockuser") {
      setModalAction("blockUser");
      setModalVisible(true);
    }
  }, []);
  const handleReportUser = useCallback(() => {
    const formData = { user_id: reportUserId, reason: reportReason };
    UserReport(formData, Token)
      .then((res) => {
        message.success(res.data.message);
        setModalVisible(false);
        setReportReason("");
      })
      .catch((e) => console.log(e, "error"));
  }, [reportUserId, reportReason, Token]);
  const handleBlockUser = useCallback(() => {
    const formData = { user_id: reportUserId, reason: reportReason };
    BlockUser(formData, Token)
      .then((res) => {
        message.success(res.data.message);
        setModalVisible(false);
        setReportReason("");
      })
      .catch((e) => console.log(e, "error"));
  }, [reportUserId, reportReason, Token]);
  const handleSubmit = useCallback(() => {
    if (modalAction === "reportUser") {
      handleReportUser();
    } else if (modalAction === "blockUser") {
      handleBlockUser();
    }
  }, [modalAction, handleReportUser, handleBlockUser]);
  const openFollowersModal = useCallback(
    () => setFollowersModalVisible(true),
    []
  );
  const openFollowingModal = useCallback(
    () => setFollowingModalVisible(true),
    []
  );
  const closeFollowersModal = useCallback(
    () => setFollowersModalVisible(false),
    []
  );
  const closeFollowingModal = useCallback(
    () => setFollowingModalVisible(false),
    []
  );
  const follower = useSelector((state) => state.followers.data);
  const following = useSelector((state) => state.following.data);
  useEffect(() => {
    if (Token && data?.id) {
      if (followersModalVisible) {
        dispatch(fetchFollowers({ Token, id: data.id }));
      } else if (followingModalVisible) {
        dispatch(fetchFollowing({ Token, id: data.id }));
      }
    }
  }, [Token, data, dispatch, followersModalVisible, followingModalVisible]);
  const handleRemoveFollower = useCallback(
    (id) => {
      RemoveFollowerApi(Token, id)
        .then((res) => {
          dispatch(fetchFollowers({ Token, id: data.id }));
          dispatch(fetchSelfProfile(Token));
          if (follower?.length === 1) setFollowersModalVisible(false);
        })
        .catch((e) => console.log(e, "error"));
    },
    [Token, dispatch, follower, data?.id]
  );
  const handleUnFollower = useCallback(
    (id) => {
      const formData = { followee_id: id };
      FollowApi(formData, Token)
        .then((res) => {
          message.success(res?.data?.message);
          dispatch(fetchFollowers({ Token, id: data.id }));
          dispatch(fetchSelfProfile(Token));
          if (following?.length === 1) setFollowingModalVisible(false);
        })
        .catch((e) => console.log(e, "error"));
    },
    [Token, dispatch, following, data?.id]
  );
  const handleViewProfile = useCallback(
    (name, id) => {
      navigate(`/beautician-profile/${name}/${encode(id)}`);
      setFollowersModalVisible(false);
      setFollowingModalVisible(false);
    },
    [navigate]
  );
  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="reportpost">Report User</Menu.Item>
        <Menu.Item key="blockuser">Block User</Menu.Item>
      </Menu>
    ),
    [handleMenuClick]
  );
  const handlepostType = useCallback(
    (type) => {
      setPostType(posttype === "profile" ? "savepost" : "profile");
    },
    [posttype]
  );
  return (
    <div className="ProfileCard">
      <Card body>
        <div className="ProfileCard_inner">
          {type === "beautician" && (
            <Dropdown overlay={menu} trigger={["click"]} className="createPost">
              <AiOutlineMore
                fontSize={30}
                style={{ cursor: "pointer", position: "absolute", right: 0 }}
                onClick={() => setReportUserId(data.id)}
              />
            </Dropdown>
          )}
          <img
            src={
              data?.user_attachments?.length === 0
                ? DummyUser
                : data?.user_attachments[0]?.file_name.startsWith("https:")
                ? data?.user_attachments[0]?.file_name
                : `${imageurl}${data?.user_attachments[0]?.file_name}`
            }
            alt="Profile"
            className="profilePhoto"
            width={100}
            height={100}
          />
          <h5>{data?.user_profile?.name}</h5>
          <div className="post_follower_following">
            <div className="post">
              <div>{data?.user_profile?.no_of_post_posted}</div>
              <div>Posts</div>
            </div>
            <div
              className="followers"
              onClick={
                data?.user_profile?.follower_count !== 0
                  ? openFollowersModal
                  : null
              }
            >
              <div>{data?.user_profile?.follower_count}</div>
              <div>Followers</div>
            </div>
            <div
              className="following"
              onClick={
                data?.user_profile?.followee_count !== 0
                  ? openFollowingModal
                  : null
              }
            >
              <div>{data?.user_profile?.followee_count}</div>
              <div>Following</div>
            </div>
          </div>
          {type !== "self" && (
            <div className="Follow_massage_btn">
              <Button className="follow_btn" onClick={handleFollow}>
                {data?.is_follow === false ? "Follow" : "Unfollow"}
              </Button>
              <Button
                className="follow_btn"
                onClick={() => handleRedirection("inbox")}
              >
                Message
              </Button>
            </div>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            {type === "self" && location.pathname !== "/profile" && (
              <Button
                className="edit_profile_btn"
                onClick={() => handleRedirection("profile")}
              >
                Profile
              </Button>
            )}
            {type === "self" && location.pathname === "/profile" && (
              <Button
                className="edit_profile_btn"
                onClick={() => handleRedirection("edit-profile")}
              >
                Edit Profile
              </Button>
            )}
            {type === "self" && location.pathname === "/profile" && (
              <Button
                className="edit_profile_btn"
                // onClick={() =>
                //   setPostType(posttype === "profile" ? "savepost" : "profile")
                // }
                onClick={() =>
                  handlepostType(
                    posttype === "profile" ? "savepost" : "profile"
                  )
                }
              >
                {posttype === "profile" ? "Save Post" : "Profile"}
              </Button>
            )}
          </div>
          {type === "beautician" && data?.role_id === 7 && (
            <Button
              className="btn_theme"
              onClick={() => handleRedirection("book-appointment")}
            >
              Book Appointment
            </Button>
          )}
          {type === "self" && data?.role_id === 7 && (
            <Button
              className="btn_theme"
              onClick={() => handleRedirection("manage-availability")}
            >
              Manage Availability
            </Button>
          )}
        </div>
      </Card>
      <Modal
        title="Report User"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            className="follow_btn mdm_btn"
            onClick={() => setModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="follow_btn mdm_btn"
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Input.TextArea
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
          placeholder="Enter the reason for reporting this user"
          rows={4}
        />
      </Modal>
      <Modal
        title="Followers"
        visible={followersModalVisible}
        onCancel={closeFollowersModal}
        footer={null}
      >
        <div className="Following">
          {follower?.map((item) => (
            <Card key={item.follower_id}>
              <div className="Following_detail">
                <div
                  className="divide"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleViewProfile(
                      item?.follow_user?.user_profile?.name,
                      item.follower_id
                    )
                  }
                >
                  <h6>
                    <img
                      src={
                        item?.follow_user?.user_attachments.length === 0
                          ? DummyUser
                          : item?.follow_user?.user_attachments[0]?.file_name.startsWith(
                              "https:"
                            )
                          ? item?.follow_user?.user_attachments[0]?.file_name
                          : `${imageurl}${item?.follow_user?.user_attachments[0]?.file_name}`
                      }
                      className="followin_picture"
                      alt=""
                    />
                    {item?.follow_user?.user_profile?.name}
                  </h6>
                </div>
                <div className="divide">
                {type !== "beautician" && (
                  <Button
                    className="edit_profile_btn"
                    onClick={() => handleRemoveFollower(item?.follower_id)}
                  >
                    Remove
                  </Button>
                )}
                </div>
              </div>
            </Card>
          ))}
        </div>
      </Modal>
      <Modal
        title="Following"
        visible={followingModalVisible}
        onCancel={closeFollowingModal}
        footer={null}
      >
        <div className="Following">
          {following?.map((item) => (
            <Card key={item.followee_id}>
              <div className="Following_detail">
                <div
                  className="divide"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleViewProfile(
                      item.followee_user?.user_profile?.name,
                      item.followee_id
                    )
                  }
                >
                  <h6>
                    <img
                      src={
                        item?.followee_user?.user_attachments.length === 0
                          ? DummyUser
                          : item?.followee_user?.user_attachments[0]?.file_name.startsWith(
                              "https:"
                            )
                          ? item?.followee_user?.user_attachments[0]?.file_name
                          : `${imageurl}${item?.followee_user?.user_attachments[0]?.file_name}`
                      }
                      className="followin_picture"
                      alt=""
                    />
                    {item?.followee_user?.user_profile.name}
                  </h6>
                </div>
                <div className="divide">
                  {type !== "beautician" && (
                    <Button
                      className="edit_profile_btn"
                      onClick={() => handleUnFollower(item?.followee_id)}
                    >
                      UnFollow
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          ))}
        </div>
      </Modal>
    </div>
  );
};
export default ProfileCard;
