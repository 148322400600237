import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import Services from "./Services/Services";
import "./Profile.scss";
import Posts from "./Posts/Posts";
import Layout from "../Layout/Layout";
import GridPhoto from "./GridPhoto/GridPhoto";
import GridReels from "./GridReels/GridReels";
import BeauticianPost from "./Posts/BeauticianPost";
import Reviews from "./Reviews/Reviews";
const ProfileBeautician = ({ data, type }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulating data fetching
    if (data) {
      setLoading(false);
    }
  }, [data]);
  console.log(data, "datadata");
  return (
    <Layout type={type}>
      <div className="profileDetails">
        {loading ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          <>
            {data?.user_role?.id !== 6 && (
              <Reviews roleid={data?.role_id} reviewed={data?.is_reviewed} />
            )}
            <p className="text">
              {data?.user_profile?.about === "undefined"
                ? "---"
                : data?.user_profile?.about}
            </p>
            {data?.user_role?.id !== 6 &&
            <Services data={data?.services} type={type} />}
            <div className="scroll_layout">
              <GridPhoto data={data?.user_albums} type={type} />
              <GridReels data={data?.user_reels} type={type} />
              <div className="PostSection">
                {/* <h6>Posts</h6> */}
                <BeauticianPost profileName={data?.user_profile?.name} profileImage={data?.user_attachments[0]?.file_name}/>
                {/* <Posts data={data?.posts} type={type} profileName={data?.user_profile?.name} profileImage={data?.user_attachments[0]?.file_name}/> */}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default ProfileBeautician;
