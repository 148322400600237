import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logoImg from "../../assest/Logo.png";
import "./Navbar.scss";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FaEdit, FaRegBell } from "react-icons/fa";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Badge,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import DummyUser from "../../assest/dunmmyuser.png";
import { useNavigate } from "react-router-dom";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../apis/host";
import UploadUserName from "../EditProfile/UploadUserName";
import { GetNotificationApi } from "../../apis/api";

function NavbarSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = localStorage.getItem("glamlinkToken");

  // State for notification menu
  const [isNotificationOpen, setIsNotificationOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLoginClick = (userType) => {
    localStorage.removeItem("role_id");
    localStorage.setItem("role_id", userType === "user" ? 6 : 7);
    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setIsNotificationOpen(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setIsNotificationOpen(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("glamlinkToken");
    localStorage.removeItem("role_id");
    localStorage.removeItem("user_id");
    navigate("/");
  };

  const [notification, setNotification] = useState();
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
    GetNotificationApi(Token)
      .then((res) => {
        setNotification(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [dispatch, Token]);

  const profile = useSelector((state) => state?.user?.profile?.data);
  const [show, setShow] = useState(false);

  const handleClosemodal = () => setShow(false);
  const handleShowmodal = () => setShow(true);

  const handleMenuItemClick = (callback) => {
    callback();
    handleClose(); // Close the menu after clicking an item
  };

  return (
    <>
      <div className="Navbar">
        <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
          <Container>
            <Navbar.Brand href="/">
              <img src={logoImg} width={100} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <>
                <Nav.Link onClick={() => navigate("/home")}>HOME</Nav.Link>
                <Nav.Link onClick={() => navigate("/explore")}>
                  EXPLORE
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/showreel")}>REELS</Nav.Link>
                <Nav.Link onClick={() => navigate("/bookings")}>
                  BOOKINGS
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/inbox")}>INBOX</Nav.Link>
                {Token && (
                  <>
                    <Nav.Link>
                      {/* Add badge icon to the bell */}
                      <Badge
                        badgeContent={notification?.length || 0}
                        color="error"
                      >
                        <FaRegBell
                          size={20}
                          onClick={handleNotificationClick}
                          style={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Nav.Link>
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <img
                          src={
                            profile?.user_attachments?.length === 0
                              ? DummyUser
                              : profile?.user_attachments[0]?.file_name.startsWith(
                                  "https:"
                                )
                              ? profile?.user_attachments[0]?.file_name
                              : `${imageurl}${profile?.user_attachments[0]?.file_name}`
                          }
                          width={100}
                          height={100}
                          alt="Profile"
                          className="profilePhoto"
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <MenuItem onClick={() => handleMenuItemClick(handleShowmodal)}>
        <ListItemIcon>
          <FaEdit size={20} />
        </ListItemIcon>
        Edit User Name
      </MenuItem>
      <MenuItem
        onClick={() => handleMenuItemClick(() => navigate("/profile"))}
      >
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        Profile
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(handleLogout)}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
                  </>
                )}
              </>
              {!Token && (
                <>
                  <DropdownButton
                    id="dropdown-button-dark-example2"
                    title="Login"
                    className="ButtonTheme2"
                    style={{ padding: "0px", backgroundColor: "transparent" }}
                  >
                    <Dropdown.Item onClick={() => handleLoginClick("user")}>
                      Login as User
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleLoginClick("beautician")}
                    >
                      Login as Beautician
                    </Dropdown.Item>
                  </DropdownButton>
                  <Nav.Link onClick={() => navigate("/signup")}>
                    <span
                      className="ButtonTheme2"
                      style={{ padding: "6.6px 8px" }}
                    >
                      Sign Up
                    </span>
                  </Nav.Link>
                </>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <Modal show={show} onHide={handleClosemodal}>
        <Modal.Body>
          <UploadUserName
            handleClosemodal={handleClosemodal}
            usename={profile?.user_name}
          />
        </Modal.Body>
      </Modal>

      {/* Notification Menu */}
   
      <Menu
        anchorEl={isNotificationOpen}
        open={Boolean(isNotificationOpen)}
        onClose={handleNotificationClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >{notification?.length===0&&
        <MenuItem  >
        Notification not Available
      </MenuItem>
      }
        {notification?.map((item, index) => {
          return (
            <MenuItem key={index} onClick={handleNotificationClose}>
              {item.message}
            </MenuItem>
          );
        })}
           {notification?.length !== 0 &&
        <MenuItem
        className="button_theme_see_all"
          onClick={() => {
            handleNotificationClose();
            navigate("/notifications"); // Navigate and close menu
          }}
        >
          See All Notifications
        </MenuItem>
}
      </Menu>
      
    </>
  );
}

export default NavbarSection;
