import React, { useEffect, useState } from "react";
import { Button, Rate, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import Layout from "../../Components/Layout/Layout";
import { fetchBookings } from "../../redux/slices/bookingsSlice";
import { imageurl } from "../../apis/host";
import "./Bookings.scss";
import { CiCalendar } from "react-icons/ci";
import moment from "moment";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import Login from "../../Components/AuthLayout/Login/Login";
import { MdStarRate } from "react-icons/md";
import { StarFilled } from "@ant-design/icons";
import { Cancel } from "@mui/icons-material";
import DummyUser from "../../assest/dunmmyuser.png";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;
const BookingsforUser = () => {
  const [activeTab, setActiveTab] = useState("UPCOMING");
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  const { bookings, loading } = useSelector((state) => state?.bookings);
  // const profile = useSelector((state) => state.user.profile?.data);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (Token) {
  //     dispatch(fetchSelfProfile(Token));
  //   } else {
  //     navigate("/login");
  //   }
  // }, [dispatch, Token,navigate]);
  useEffect(() => {
    dispatch(fetchBookings({ Token: Token, status: activeTab }));
  }, [dispatch, Token, activeTab]);
  const getButtonStyle = (status) => {
    switch (status) {
      case "UPCOMING":
        return "newRequestBtn";
      case "PENDING":
        return "upcomingBtn";
      case "COMPLETED":
        return "completedBtn";
      case "CANCELED":
        return "Canclebtn";
      default:
        return "defaultBtn";
    }
  };
  const renderBookingCards = () => {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (bookings?.length === 0) {
      return <p>No data available</p>;
    }
    return bookings?.map((item, index) => (
      <div className="bookingCard" key={index}>
        <div className="profileContainer">
          <div className="profile">
            <img
              src={
                item?.appointment_counselor?.user_attachments?.length === 0
                  ? DummyUser
                  : item?.appointment_counselor?.user_attachments[0]?.file_name?.includes(
                      "https:"
                    )
                  ? item?.appointment_counselor?.user_attachments[0]?.file_name
                  : `${imageurl}${item?.appointment_counselor?.user_attachments[0]?.file_name}`
              }
              alt=""
              className="Appointment_profile_picture"
            />
            <div className="name">
              <h6>{item?.appointment_counselor?.user_profile?.name}</h6>
              <div>
                <StarFilled className="start_icon" />
                <small style={{ fontWeight: "700", margin: "0px 5px" }}>
                  {item?.appointment_counselor?.user_profile?.overall_ratings}
                </small>
              </div>
              {/* <Rate
                disabled
                // defaultValue={
                //   item?.appointment_counselor?.user_profile?.overall_ratings
                // }
                defaultValue={1}
                value={1}
              /> */}
            </div>
          </div>
          <div>
            <div
              className={`${getButtonStyle(activeTab)} active_btn_type`}
              // className="active_btn_type"
              style={{ borderRadius: "16px" }}
            >
              {activeTab === "PENDING"
                ? "Pending"
                : activeTab === "CANCELED"
                ? "Canceled"
                : activeTab === "COMPLETED"
                ? "Completed"
                : "Upcoming"}
            </div>
            {item.cancelation_reason && (
              <div>
                <h6>
                  <span style={{ color: "red" }}>Reason</span> :
                  {item?.cancelation_reason}
                </h6>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="Date_time_section">
            <div>
              <CiCalendar size={20} />
            </div>
            <div className="date_time">
              <h6 className="date">
                {moment(item?.appointment_slot?.date).format("D MMMM YYYY")}
              </h6>
              <h6 className="date">|</h6>
              <h6 className="date">
                {item?.appointment_slot?.start_time_local}
              </h6>
            </div>
          </div>
          <p className="text">
            {item?.appointment_user?.user_profile?.about==="undefined"?"---":item?.appointment_user?.user_profile?.about.substring(0, 150)}
          </p>
        </div>
      </div>
    ));
  };
  return (
    <>
      <Layout  type="self">
        <div className="Booking">
          <h4 className="heading">Bookings</h4>
          <Tabs
            defaultActiveKey="UPCOMING"
            onChange={(key) => setActiveTab(key)}
          >
            <TabPane tab="UPCOMING" key="UPCOMING">
              {renderBookingCards()}
            </TabPane>
            <TabPane tab="PENDING" key="PENDING">
              {renderBookingCards()}
            </TabPane>
            <TabPane tab="CANCELED" key="CANCELED">
              {renderBookingCards()}
            </TabPane>
            {/* <TabPane tab="Completed" key="COMPLETED">
            {renderBookingCards()}
          </TabPane> */}
          </Tabs>
        </div>
      </Layout>
    </>
  );
};
export default BookingsforUser;
