// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAbL6rjr5yKu7HmVW9suK1A_EMWf_hZjvk",
  authDomain: "glamlink-5585b.firebaseapp.com",
  databaseURL: "https://glamlink-5585b-default-rtdb.firebaseio.com",
  projectId: "glamlink-5585b",
  storageBucket: "glamlink-5585b.appspot.com",
  messagingSenderId: "708221743036",
  appId: "1:708221743036:web:141e48c1411cb5c69605b9",
  measurementId: "G-8X4PMLPMDM",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getDatabase(app);

export { auth, provider, db };
