import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import "./TimeSlot.scss";
import moment from "moment";
import momentTz from "moment-timezone";
import { CreateAppointment, GetAllSlot } from "../apis/api";
import { decode } from "base-64";
import { Button, message } from "antd";
// import { isSlotPast } from './slotfunction';
const getWeekDates = (offset = 0) => {
  const current = new Date();
  const first = current.getDate() + offset * 7; // Starting from today
  const dates = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(current.setDate(first + i));
    return new Date(date.setHours(0, 0, 0, 0)); // Reset time to midnight
  });
  return dates;
};
const TimeSlot = ({ beauticianId, selectedService, appointmentReason }) => {
  const Token = localStorage.getItem("glamlinkToken");
  const [timeSlots, setTimeSlots] = useState({});
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [currentWeekOffset, setCurrentWeekOffset] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [anySlotSelected, setAnySlotSelected] = useState(false); // Track if any slot is selected
  const [sendData, setSendData] = useState({});
  const [idset, setId] = useState();
  const weekDates = getWeekDates(currentWeekOffset);
  useEffect(() => {
    fetchTimeSlots();
  }, [selectedDate, beauticianId, Token]);
  const fetchTimeSlots = () => {
    if (selectedDate && beauticianId && Token) {
      const decodedId = decode(beauticianId);
      setId(decodedId);
      const formData = {
        counselor_id: decodedId,
        dates: selectedDate,
      };
      GetAllSlot(formData, Token)
        .then((res) => {
          setTimeSlots(res.data.data);
          setLoadingSlots(true);
        })
        .catch((e) => {
          console.log(e, "error");
        });
    }
  };
  const handlePreviousWeek = () => {
    setCurrentWeekOffset(currentWeekOffset - 1);
    setSelectedDate(null);
    setSelectedSlots([]);
    setAnySlotSelected(false); // Unselect all slots when changing week
  };
  const handleNextWeek = () => {
    setCurrentWeekOffset(currentWeekOffset + 1);
    setSelectedDate(null);
    setSelectedSlots([]);
    setAnySlotSelected(false); // Unselect all slots when changing week
  };
  const handleDateClick = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };
  const isSelectedDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return selectedDate && formattedDate === selectedDate;
  };
  const isTimeSlotSelected = (dateKey, startTime) => {
    return selectedSlots.some(
      (slot) => slot.dateKey === dateKey && slot.start_time === startTime
    );
  };
  const handleTimeSlotClick = (
    dateKey,
    startTime,
    endTime,
    availability_id
  ) => {
    if (anySlotSelected) {
      // If any slot is selected, unselect all slots
      setSelectedSlots([]);
      setAnySlotSelected(false);
    } else {
      // Otherwise, toggle selection of the clicked slot
      setSelectedSlots([
        { dateKey, start_time: startTime, end_time: endTime, availability_id },
      ]);
      setAnySlotSelected(true);

      setSendData({
        counselor_id: idset,
        start_time: startTime,
        end_time: endTime,
        notes: appointmentReason,
        service_id: selectedService,
        date: selectedDate,
        availability_id: availability_id,
      });
    }
  };
  const handleBook = () => {
    CreateAppointment(sendData, Token)
      .then((res) => {
        message.success("Successfully booked");
        setSelectedSlots([]);
        setAnySlotSelected(false);
        fetchTimeSlots();
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const isSlotPast = (date, time) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDateTime = momentTz().tz(timezone);
    const slotDateTime = momentTz.tz(
      `${date} ${time}`,
      "YYYY-MM-DD H:mm A ",
      timezone
    );
    return slotDateTime.isBefore(currentDateTime);
  };
  const [showslot, setShowSlot] = useState(false);

  const handleButtonClick = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
    setSelectedDate(formattedDate);
    setShowSlot(true);
  };
  return (<>
      {!showslot && (
        <div className="slot_btn">
          <Button onClick={handleButtonClick} className="book_btn">
            Book Now
          </Button>
        </div>
      )}
    <div className="container">
      {selectedDate && (
        <div className="weekdays">
          <button
            onClick={handlePreviousWeek}
            style={{ border: "none", background: "none", cursor: "pointer" }}
            disabled={currentWeekOffset === 0}
          >
            <ArrowLeftOutlined size={20} />
          </button>
          <div
            className="week-dates"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {weekDates.map((date, index) => (
              <div
                key={index}
                style={{ flex: 1, textAlign: "center", cursor: "pointer" }}
                onClick={() => handleDateClick(date)}
              >
                <div
                  style={{
                    ...{ textAlign: "center", cursor: "pointer" },
                    ...(isSelectedDate(date)
                      ? {
                          backgroundColor: "#25B8CB",
                          color: "white",
                          borderRadius: "5px",
                          padding: "5px",
                        }
                      : {}),
                  }}
                >
                  <p style={{ marginBottom: "3px" }}>
                    {date.toLocaleString("default", { weekday: "short" })}
                  </p>
                  {` ${date.toLocaleString("default", {
                    month: "short",
                  })} ${date.getDate()}`}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNextWeek}
            style={{ border: "none", background: "none", cursor: "pointer" }}
          >
            <ArrowRightOutlined size={20} />
          </button>
        </div>
      )}
      {selectedDate && timeSlots && (
        <div className="slotsection">
          {timeSlots[selectedDate]?.map((item, index) => {
            const isSelected = isTimeSlotSelected(
              item.dateKey,
              item.start_time
            );
            const isDisabled =
              item.available_seats === 0 ||
              isSlotPast(selectedDate, item.start_time);
            return (
              <div
                key={index}
                className="slots"
                style={{
                  color: isSelected ? "white" : "unset",
                  backgroundColor: isSelected ? "#155182" : "unset",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  opacity: isDisabled ? 0.5 : 1,
                }}
                onClick={() => {
                  if (!isDisabled) {
                    handleTimeSlotClick(
                      item.dateKey,
                      item.start_time,
                      item.end_time,
                      item.availability_id
                    );
                  }
                }}
              >
                {item?.start_time}
              </div>
            );
          })}
        </div>
      )}

      {selectedSlots.length !== 0 && sendData && (
        <div>
          <Button onClick={handleBook}>Confirm Booking</Button>
        </div>
      )}
    </div>
  </>
  );
};
export default TimeSlot;
