import React, { useEffect, useRef, useState } from "react";
import { LuUpload } from "react-icons/lu";
import { Input, Form, Button, TimePicker, message, Select } from "antd";
import "./ServiceForm.scss";
import moment from "moment";
import { AddServicesApi, GetCategoryApi, GetServicesByApi } from "../../apis/api";
import { useDispatch } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";

const ServiceForm = ({ setIsModalVisible }) => {
  const [form] = Form.useForm();

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [category, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  useEffect(() => {
    GetCategoryApi(Token)
      .then((res) => {
        setCategory(res?.data?.data?.rows);
    
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [Token]);

  const handleCategoryChange = (categoryId) => {
    // console.log(categoryId,"catee")
    const formData = { category_id: categoryId };
    GetServicesByApi(formData, Token)
      .then((res) => {
        // console.log(res,"res in servies list")
        const serviceList = res?.data?.data || [];
        setServices(serviceList); // Set the fetched services in the state
        form.setFieldsValue({ name: null }); // Clear the selected service name when category changes
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const onFinish = (values) => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("duration", values.duration.format("HH:mm"));
    formData.append("category_id", values.category);

    AddServicesApi(formData, Token)
      .then((res) => {
        message.success("Service updated successfully!");
        dispatch(fetchSelfProfile(Token));
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((error) => {
        message.error("Failed to update service.");
      });
  };

  return (
    <div className="serviceForm">
      <div className="header">
        <h3>Service Form</h3>
        <Button
          type="primary"
          htmlType="submit"
          form="serviceForm"
          className="savebtn"
        >
          Save Service
        </Button>
      </div>
      <Form
        id="serviceForm"
        onFinish={onFinish}
        className="form"
        layout="vertical"
        form={form}
      >
        <div className="inputSection">
          <Form.Item
            label="Service Category"
            name="category"
            rules={[{ required: true, message: "Please select a service type" }]}
          >
            <Select
              size="large"
              placeholder="Select service type"
              onChange={handleCategoryChange} // Trigger fetching services when category changes
            >
              {category?.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Service Name"
            name="name"
            rules={[{ required: true, message: "Please select a service name" }]}
          >
            <Select
              size="large"
              placeholder="Select service name"
              disabled={!services.length} // Disable if no services are available
            >
              {services.map((service) => (
                <Select.Option key={service.id} value={service.title}>
                  {service.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please enter service description" },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter service description"
              className="inputs"
            />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input size="large" placeholder="Enter price" className="inputs" />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true, message: "Please enter duration" }]}
          >
            <TimePicker
              format="HH:mm"
              size="large"
              className="inputs"
              style={{ width: "100%" }}
              minuteStep={30}
              defaultOpenValue={moment("00:00", "HH:mm")}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ServiceForm;
