import React, { useEffect, useState } from "react";
import "../Profile.scss";
import { HiOutlinePlusSm } from "react-icons/hi";
import { Modal, Input, Button, Skeleton } from "antd";
import { CreateAlbum } from "../../../apis/api";
import { imageurl } from "../../../apis/host";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { encode } from "base-64";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAlbums } from "../../../redux/slices/albumSlice";
import NodataImage from "../../../assest/nodata1.jpg";
import { Container } from "react-bootstrap";
const GridPhoto = ({ data, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [albumTitle, setAlbumTitle] = useState("");
  const {id}=useParams()
  const [loading, setLoading] = useState(true); // Add loading state
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false); // State variable to track whether to show all items
  const Token = localStorage.getItem("glamlinkToken");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setLoading(true); // Start loading
    try {
      await CreateAlbum(albumTitle, Token);
      setIsModalOpen(false);
      setAlbumTitle(""); // Reset the input field
      dispatch(fetchAllAlbums(Token));
    } catch (error) {
      console.error("Error creating album", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (e) => {
    setAlbumTitle(e.target.value);
  };
  const navigate = useNavigate();
  const handleOpenAlbum = (item) => {
    console.log(item)
    if (type !== "beautician") {
      navigate(`/profile/album-section/${item.title}`, {
        state: { data: item },
      });
    } else {
      navigate(`/profile/album-section/${item?.title}/${id}`, {
        state: { data: item },
      });
    }
  };
  useEffect(() => {
    if (Token) {
      dispatch(fetchAllAlbums(Token));
    }
  }, [dispatch, Token]);
  const temp = useSelector((state) => state.album.albums?.data?.rows);
  const albums = type === "beautician" ? data : temp;
  const status = useSelector((state) => state.album.status);
  const error = useSelector((state) => state.album.error);
  useEffect(() => {
    if (data || status !== "loading") {
      setLoading(false);
    }
  }, [data, status]);
  if (status === "failed") {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="photos">
      <div>
        <div className="top">
          <div className="section1">
            <h6 style={{ marginBottom: "0px" }}>Albums</h6>
            {type !== "beautician" && (
              <small onClick={showModal} style={{ cursor: "pointer" }}>
                <HiOutlinePlusSm /> Create New
              </small>
            )}
          </div>
          {data?.length !== 0 &&
            (showAll ? (
              <div onClick={() => setShowAll(false)}>
                Show less <MdOutlineKeyboardArrowUp />
              </div>
            ) : (
              <div onClick={() => setShowAll(true)}>
                Show all <MdOutlineKeyboardArrowUp />
              </div>
            ))}
        </div>
        {loading ? (
          <Skeleton active />
        ) : data?.length === 0 ? (
          <img
            src={NodataImage}
            alt="No data Found"
            style={{ margin: "20px  0px" }}
          />
        ) : (
          <div className="photoGrid">
            {showAll
              ? albums.map((photo, index) => (
                  <div
                    className="photoContainer"
                    key={index}
                    onClick={() => handleOpenAlbum(photo)}
                  >
                    {photo.album_attachments.length !== 0 ? (
                      <img
                        src={
                          photo.album_attachments[0].file_name.split("/")[0] ===
                          "https:"
                            ? photo.album_attachments[0].file_name
                            : `${imageurl}${photo.album_attachments[0].file_name}`
                        }
                        alt={`Albums ${index + 1}`}
                        className="photo"
                        width="100"
                      />
                    ) : (
                      <img
                        src="https://lh5.googleusercontent.com/proxy/t08n2HuxPfw8OpbutGWjekHAgxfPFv-pZZ5_-uTfhEGK8B5Lp-VN4VjrdxKtr8acgJA93S14m9NdELzjafFfy13b68pQ7zzDiAmn4Xg8LvsTw1jogn_7wStYeOx7ojx5h63Gliw"
                        alt={`Albums ${index + 1}`}
                        className="photo"
                        width="100"
                      />
                    )}
                    <h6>{photo.title}</h6>
                  </div>
                ))
              : albums?.slice(0, 5).map((photo, index) => (
                  <div
                    className="photoContainer"
                    key={index}
                    onClick={() => handleOpenAlbum(photo)}
                  >
                    {photo.album_attachments.length !== 0 ? (
                      <img
                        src={
                          photo.album_attachments[0].file_name.split("/")[0] ===
                          "https:"
                            ? photo.album_attachments[0].file_name
                            : `${imageurl}${photo.album_attachments[0].file_name}`
                        }
                        alt={`Albums ${index + 1}`}
                        className="photo"
                        width="100"
                      />
                    ) : (
                      <img
                        src="https://lh5.googleusercontent.com/proxy/t08n2HuxPfw8OpbutGWjekHAgxfPFv-pZZ5_-uTfhEGK8B5Lp-VN4VjrdxKtr8acgJA93S14m9NdELzjafFfy13b68pQ7zzDiAmn4Xg8LvsTw1jogn_7wStYeOx7ojx5h63Gliw"
                        alt={`Albums ${index + 1}`}
                        className="photo"
                        width="100"
                      />
                    )}
                    <div className="photo_title">
                      <div>{photo.title}</div>
                      <div>|</div>
                      <div>{photo.album_attachments?.length} Photos</div>
                    </div>
                  </div>
                ))}
          </div>
        )}
      </div>
      <Modal
        title="Create New Album"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
          >
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter album title"
          value={albumTitle}
          onChange={handleChange}
        />
      </Modal>
    </div>
  );
};
export default GridPhoto;
