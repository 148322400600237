import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  CityApi,
  RegisterAPi,
  StateApi,
  ProfessionApi,
} from "../../../apis/api";
import logoImg from "../../../assest/Logo.png";
import AddressLookup from "./AddressLookup";
import "./CreateAccount.scss";

const { TextArea } = Input;
const { Option } = Select;

const CreateAccount = () => {
  const [addressmap, setAddressMap] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [isFirstSelection, setIsFirstSelection] = useState(true);
  const [addressError, setAddressError] = useState(false); // State for address error
  const navigate = useNavigate();
  const roleid = localStorage.getItem("role_id");
  const [profession, setProfession] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    StateApi()
      .then((res) => setStates(res?.data?.data))
      .catch((e) => console.log(e));

    ProfessionApi()
      .then((res) => setProfession(res?.data?.data?.rows))
      .catch((e) => console.log(e));
  }, []);

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity("");
    setCities([]);
    CityApi(value)
      .then((res) => setCities(res?.data?.data?.all_city))
      .catch((e) => {
        console.log(e);
        setCities([]);
      });
  };

  const handleAddressChange = (address, lat, lng) => {
    if (isFirstSelection) {
      setIsFirstSelection(false);
    }
    setAddressMap(address);
    setAddress(address);
    setLatitude(lat);
    setLongitude(lng);
    setAddressError(false); // Reset error state on valid address selection
  };

  const onFinish = async (values) => {
    if (!latitude || !longitude) {
      setAddressError(true);
      message.error("Please select your address using the map.");
      return;
    }

    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = {
      timezone: systemTimeZone,
      role_id: roleid,
    };
    const formData = new FormData();
    if (selectedImage) {
      formData.append("images", selectedImage);
    }
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("address", address);
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    formData.append("mobile", values?.mobile);
    formData.append("profession", values?.profession);
    formData.append("user_name", values?.user_name);
    formData.append("password", values?.password);
    formData.append("confirm_password", values?.confirmPassword);
    formData.append("about", values?.about);
    formData.append("state_id", selectedState);
    formData.append("city_id", selectedCity);

    RegisterAPi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message);
        navigate(`/verify-account?email=${encodeURIComponent(values.email)}`);
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const removeImage = () => {
    setSelectedImage("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes?.includes(file.type)) {
        message.error("You can only upload JPG, JPEG, or PNG files.");
        return;
      }
      setSelectedImage(file);
    }
  };

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  return (
    <div className="createAccount">
      <div className="containt">
        <img className="logo" src={logoImg} width={150} alt="logo" />
        <h3>Create Your Account</h3>
      </div>
      <div className="form">
        <p className="lable">Profile picture</p>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 25 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="profileForm">
            <div className="profiletop">
              {selectedImage === "" ? (
                <img
                  src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                  alt="Profile"
                  className="Profile_top_images"
                  style={{ borderRadius: "100px" }}
                />
              ) : (
                selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    className="Profile_top_images"
                    alt="Profile Pic"
                    style={{ borderRadius: "50px" }}
                  />
                )
              )}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button size="larg" style={{ marginRight: "20px" }}>
                <label className="custom_file_upload">
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleFileChange}
                  />
                  Upload image
                </label>
              </Button>
              <Button size="larg" onClick={removeImage}>
                Remove
              </Button>
              <p style={{ marginTop: "10px" }} className="lable">
                You can upload jpg, gif, or png image files. Max size of 3mb.
              </p>
            </div>
          </div>

          <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true, message: "Please enter your full name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User Name"
            name="user_name"
            rules={[
              { required: true, message: "Please enter your username" },
              {
                pattern: /^[a-z]([a-z._]*[a-z_])?$/,
                message:
                  "Username must start with a letter, can include lowercase letters, full stops, and underscores, and cannot end with a full stop",
              },
            ]}
          >
            <Input placeholder="Enter User Name Example:bhvya.soni" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mobile Number"
            name="mobile"
            rules={[
              { required: true, message: "Please enter your mobile number" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="State" name="state">
            <Select value={selectedState} onChange={handleStateChange}>
              {states.map((state) => (
                <Option key={state.id} value={state.id}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="City" name="city">
            <Select
              value={selectedCity}
              onChange={(value) => setSelectedCity(value)}
            >
              {cities.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={toggleMap}>
              {showMap ? "Hide Map" : "Show Map"}
            </Button>
          </Form.Item>

          {showMap && (
            <Form.Item label="Address">
              <AddressLookup onAddressChange={handleAddressChange} />
            </Form.Item>
          )}
          {isFirstSelection === false && (
            <Form.Item label="Address">
              <Input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter address manually or select from map"
              />
            </Form.Item>
          )}
          {addressError && (
            <div style={{ color: "red", marginBottom: "20px" }}>
              Please select your address using the map.
            </div>
          )}

          <Form.Item label="About" name="about">
            <TextArea rows={4} />
          </Form.Item>
{roleid === "7" && 

          <Form.Item
            label="Profession"
            name="profession"
            rules={[
              { required: true, message: "Please select your profession" },
            ]}
          >
            <Select>
              {profession.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
}

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn_theme">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateAccount;
