import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FollowersApi } from '../../apis/api';


export const fetchFollowers = createAsyncThunk(
  'followers/fetchFollowers',
  async ({ Token, id }) => {
    const response = await FollowersApi(Token, id);
    return response.data.data;
  }
);

const followersSlice = createSlice({
  name: 'followers',
  initialState: {
    data: [],
    status: 'idle',
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFollowers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFollowers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchFollowers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default followersSlice.reducer;
