import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, Marker, InfoWindow, StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  height: "300px",
  width: "100%",
};

const AddressLookup = ({ address, onAddressChange }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBwWrA2Zwyk_XXqBzGdp7B1Bt9mXCLG5CA",
    libraries,
  });

  const [markerPosition, setMarkerPosition] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(address || "");
  const [mapCenter, setMapCenter] = useState({ lat: 51.505, lng: -0.09 });
  const searchBoxRef = useRef(null);

  const onMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    setMapCenter({ lat, lng });
    fetchAddress(lat, lng);
  }, []);

  const onPlacesChanged = useCallback(() => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setMarkerPosition({ lat, lng });
      setMapCenter({ lat, lng });
      fetchAddress(lat, lng);
    }
  }, []);

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBwWrA2Zwyk_XXqBzGdp7B1Bt9mXCLG5CA`
      );
      const data = await response.json();
      if (data.results && data.results[0]) {
        const formattedAddress = data.results[0].formatted_address;
        setCurrentAddress(formattedAddress);
        onAddressChange(formattedAddress, lat, lng);
      }
    } catch (error) {
      console.error("Failed to fetch address", error);
    }
  };

  const handleAddressChange = (event) => {
    const newAddress = event.target.value;
    setCurrentAddress(newAddress);
    onAddressChange(newAddress);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={13}
        onClick={onMapClick}
      >
        <StandaloneSearchBox
          onLoad={(ref) => (searchBoxRef.current = ref)}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search for a location"
            style={{
              boxSizing: "border-box",
              border: "1px solid transparent",
              width: "240px",
              height: "32px",
              padding: "0 12px",
              borderRadius: "3px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
              fontSize: "14px",
              outline: "none",
              textOverflow: "ellipsis",
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
              top: "10px",
            }}
          />
        </StandaloneSearchBox>
        {markerPosition && (
          <Marker position={markerPosition}>
            <InfoWindow position={markerPosition}>
              <div>
                <span>Selected location:</span>
                <p>{currentAddress}</p>
              </div>
            </InfoWindow>
          </Marker>
        )}
      </GoogleMap>
      {/* <input
        type="text"
        value={currentAddress}
        onChange={handleAddressChange}
        placeholder="Edit address"
      /> */}
    </div>
  );
};

export default AddressLookup;
