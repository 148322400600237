import React, { useEffect, useState } from "react";
import { TimePicker, Select, Button, Input, message } from "antd";
import { FaArrowLeft } from "react-icons/fa6";
import profileImage from "../../assest/profile.png";
import "./Availability.scss";
import { AddManageAvailablity } from "../../apis/api";
import moment from "moment"; // Import moment for time formatting
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";

const { Option } = Select;

const Availability = () => {
  const [availabilities, setAvailabilities] = useState([
    {
      selectedDay: "",
      maximumAvailableSeats: "",
      startTime: null,
      endTime: null,
    },
  ]);
  const Token = localStorage.getItem("glamlinkToken");

  const handleDayChange = (index) => (value) => {
    const newAvailabilities = [...availabilities];
    newAvailabilities[index].selectedDay = value;
    setAvailabilities(newAvailabilities);
  };

  const handleSeatsChange = (index) => (event) => {
    const newAvailabilities = [...availabilities];
    newAvailabilities[index].maximumAvailableSeats = event.target.value;
    setAvailabilities(newAvailabilities);
  };

  const handleTimeChange = (index, type) => (time) => {
    const newAvailabilities = [...availabilities];
    newAvailabilities[index][type] = time;
    setAvailabilities(newAvailabilities);
  };

  const handleSave = (index) => {
    const availability = availabilities[index];
    const { startTime, endTime, selectedDay, maximumAvailableSeats } =
      availability;

    if (!selectedDay || !startTime || !endTime || !maximumAvailableSeats) {
      message.error("Please complete all fields before saving.");
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const availabilityData = {
      slot_duration_in_minuites: "30",
      timezone: timezone,
      start_time: startTime.format("hh:mm A"), // Format time to AM/PM
      end_time: endTime.format("hh:mm A"), // Format time to AM/PM
      day: selectedDay,
      maximum_available_seats: maximumAvailableSeats,
    };

    AddManageAvailablity(availabilityData, Token)
      .then((res) => {
        if (res.data.success) {
          message.success("Availability Created Successfully.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Failed to create availability");
      });
  };

  const handleAddAvailability = () => {
    setAvailabilities([
      ...availabilities,
      {
        selectedDay: "",
        maximumAvailableSeats: "",
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const format = "h:mm A";

  const renderAvailabilities = () => {
    return availabilities.map((availability, index) => (
      <div key={index} className="availabilityItem">
        <div className="formGroup">
          <label>Select a weekday:</label>
          <Select
            value={availability.selectedDay}
            onChange={handleDayChange(index)}
            style={{ width: "100%", marginBottom: 15 }}
          >
            <Option value="">-- Select --</Option>
            <Option value="Monday">Monday</Option>
            <Option value="Tuesday">Tuesday</Option>
            <Option value="Wednesday">Wednesday</Option>
            <Option value="Thursday">Thursday</Option>
            <Option value="Friday">Friday</Option>
            <Option value="Saturday">Saturday</Option>
            <Option value="Sunday">Sunday</Option>
          </Select>
        </div>
        <div className="formGroup">
          <label htmlFor={`maximumAvailableSeats${index}`}>
            Maximum Available Seats:
          </label>
          <Input
            id={`maximumAvailableSeats${index}`}
            value={availability.maximumAvailableSeats}
            onChange={handleSeatsChange(index)}
            type="number"
            style={{ width: "100%", marginBottom: 15 }}
          />
        </div>
        <div className="formGroup">
          <label>Time Slot:</label>
          <div className="timeSlot">
            <TimePicker
              value={availability.startTime}
              onChange={handleTimeChange(index, "startTime")}
              style={{ marginRight: 10 }}
              format={format} // Use AM/PM format
              use12Hours
            />
            <TimePicker
              value={availability.endTime}
              onChange={handleTimeChange(index, "endTime")}
              format={format} // Use AM/PM format
              use12Hours
            />
          </div>
        </div>
        <Button
         className="button_theme"
         
          onClick={() => handleSave(index)}
          style={{ marginTop: 30 ,color:"white"}}
        >
          Submit
        </Button>
      </div>
    ));
  };

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchSelfProfile(Token));
  // }, [dispatch, Token]);
  // const profile = useSelector((state) => state?.user?.profile?.data);

  return (
    <Layout type="self">
      <div className="availability">
        <div className="header">
          <div className="d-flex align-items-center justify-content-center">
            <FaArrowLeft fontSize={22} />
            <h3 style={{ marginLeft: "10px" }}>Manage Availability</h3>
          </div>
        </div>

        {renderAvailabilities()}
        {/* <Button
          type="primary"
          onClick={handleAddAvailability}
          style={{ marginTop: 20 }}
        > */}
        <h6 onClick={handleAddAvailability}>+ Add More Availability</h6>
        {/* </Button> */}
      </div>
    </Layout>
  );
};

export default Availability;
