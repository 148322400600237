import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { Container } from "react-bootstrap";
import { FaPaperclip } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { ref, push, set, onValue, get } from "firebase/database";
import { db } from "../firebase";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import DummyUser from "../../assest/dunmmyuser.png";

import { fetchBeauticianProfile } from "../../redux/slices/beauticianSlice";
import "./Inbox.scss";
import { imageurl } from "../../apis/host";
const MessageInbox = () => {
  const [newMessage, setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const Token = localStorage.getItem("glamlinkToken");
  const profile = useSelector((state) => state?.user?.profile?.data);
  const beautician = useSelector(
    (state) => state?.beauticianprofile?.profile?.data
  );

  const sender = profile?.id; // Dynamic sender ID
  const receiver = Number(id); // Dynamic receiver ID

  const receiverProfile = {
    user_profile: {
      name: beautician?.user_profile?.name,
      profile_img: beautician?.user_attachments[0]?.file_name,
    },
  }; // Receiver profile info
  const senderProfile = {
    user_profile: {
      name: profile?.user_profile?.name,
      profile_img: profile?.user_attachments[0]?.file_name,
    },
  };

  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
    dispatch(fetchBeauticianProfile(id, Token));
  }, [dispatch, Token, id]);

  useEffect(() => {
    const chatPath1 = `chats/${sender}_${receiver}`;
    const chatPath2 = `chats/${receiver}_${sender}`;
    const messagesRef1 = ref(db, chatPath1);
    const messagesRef2 = ref(db, chatPath2);

    const loadMessages = (snapshot) => {
      if (snapshot.exists()) {
        const messages = [];
        snapshot.forEach((childSnapshot) => {
          messages.push(childSnapshot.val());
        });
        setChatMessages(messages);
      }
    };

    onValue(messagesRef1, (snapshot) => loadMessages(snapshot));
    onValue(messagesRef2, (snapshot) => loadMessages(snapshot));
  }, [sender, receiver]);

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      console.error("Please enter a message");
      return;
    }

    const messageObject = {
      message: newMessage.trim(),
      read: false,
      sender,
      sender_name: senderProfile.user_profile.name,
      sender_profile: senderProfile.user_profile.profile_img,
      receiver,
      receiver_name: receiverProfile.user_profile.name,
      receiver_profile: receiverProfile.user_profile.profile_img,
      timestamp: new Date().getTime(), // Use milliseconds for timestamp
    };

    const chatPath1 = `chats/${sender}_${receiver}`;
    const chatPath2 = `chats/${receiver}_${sender}`;

    const chatRef1 = ref(db, chatPath1);
    const chatRef2 = ref(db, chatPath2);

    try {
      const snapshot1 = await get(chatRef1);

      if (snapshot1.exists()) {
        await push(chatRef1, messageObject);
      } else {
        const snapshot2 = await get(chatRef2);
        if (snapshot2.exists()) {
          await push(chatRef2, messageObject);
        } else {
          await set(chatRef1, true);
          await push(chatRef1, messageObject);
        }
      }
      setNewMessage("");
    } catch (e) {
      console.error("Error sending message: ", e);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  return (
    <div className="inboxContainer">
      <Container>
        <div className="chatSection">
          <div className="selectedUser">
            <div className="selectedUserHeader">
              <div className="user_icon_name">
                <div>
                  <img
                    // src={`${imageurl}${receiverProfile.user_profile.profile_img}`}
                    src={
                      receiverProfile.user_profile.profile_img === null
                        ? DummyUser
                        : receiverProfile.user_profile.profile_img?.includes(
                            "https:"
                          )
                        ? receiverProfile.user_profile.profile_img
                        : `${imageurl}${receiverProfile.user_profile.profile_img}`
                    }
                    className="user_image"
                    alt=""
                  />
                </div>

                <div>
                  <div className="userName">
                    {receiverProfile.user_profile.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="chatContainer scrollableContainer">
              {/* Display chat messages */}
              {chatMessages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.sender === sender ? "sent" : "received"
                  }`}
                >
                  <div className="messageContent">{message.message}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="messageInputContainer">
            <Input
              placeholder="Type something..."
              value={newMessage}
              onChange={handleInputChange}
              suffix={<FaPaperclip className="attachmentIcon" />}
              className="messageInput"
              onKeyPress={handleKeyPress}
            />
            <IoSend className="sendButton" onClick={sendMessage} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MessageInbox;
