import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./ShareReel.scss";
import { Input, Upload, message, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { AddReel } from "../../apis/api";
import { imageurl } from "../../apis/host";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;
const ShareReel = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const [videoFiles, setVideoFiles] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [text, setText] = useState("");
  const [posting, setPosting] = useState(false); // State to track posting state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (Token) {
      dispatch(fetchSelfProfile(Token));
    } else {
      navigate("/login");
    }
  }, [dispatch, Token, navigate]);
  const profile = useSelector((state) => state.user.profile.data);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  if (loading) {
    return <Container style={{height:"100dvh"}}>Loading...</Container>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleVideoChange = ({ fileList }) => {
    if (fileList.length > 1) {
      fileList = [fileList[fileList.length - 1]];
    }
    setVideoFiles(fileList);
  };
  const handleThumbnailChange = ({ fileList }) => {
    const file = fileList.length ? fileList[0] : null;
    if (file && !["image/jpeg", "image/jpg", "image/png"]?.includes(file.type)) {
      message.error("Only Images(.jpeg, .jpg, .png) are allowed!");
      return;
    }
    setThumbnailFile(file);
  };
  const handlePost = () => {
    setPosting(true); // Set posting state to true
    const formData = new FormData();
    formData.append("description", text);
    videoFiles.forEach((file) => {
      formData.append("videos", file.originFileObj);
    });
    if (thumbnailFile) {
      formData.append("images", thumbnailFile.originFileObj);
    }
    AddReel(formData, Token)
      .then((response) => {
        message.success("Post added successfully");
        navigate(-1);
        setText("");
        setVideoFiles([]);
        setThumbnailFile(null);
      })
      .catch((error) => {
        console.error("Error adding post:", error);
        message.error("Error adding post");
      })
      .finally(() => {
        setPosting(false); // Reset posting state
      });
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Add a Video</div>
    </div>
  );
  const uploadThumbnailButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Add a Thumbnail</div>
    </div>
  );
  const handleClearData = () => {
    setText("");
    setVideoFiles([]);
    setThumbnailFile(null);
  };
  return (
    <div className="sharePostContainer">
      <Container>
        <div className="top_heading">
          <div>
            <h3>Share Reel</h3>
          </div>
          <div>
            <button className="cancelBtn" onClick={handleClearData}>
              Cancel
            </button>
            <button className="postBtn" onClick={handlePost} disabled={posting}>
              {posting ? <Spin size="small" /> : "Post"}
            </button>
          </div>
        </div>
        <div className="sharePosts">
          <div className="profile">
            {profile?.user_attachments?.length === 0 ? (
              <img
                src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                width={50}
                height={50}
                alt="Profile"
                className="profilePhoto"
              />
            ) : (
              <img
              src={
                profile?.user_attachments?.length === 0
                  ? "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                  : profile?.user_attachments[0]?.file_name.startsWith("https:")
                  ? profile?.user_attachments[0]?.file_name
                  : `${imageurl}${profile?.user_attachments[0]?.file_name}`
              }
                width={50}
                height={50}
                alt="Profile"
                className="profilePhoto"
              />
            )}
            <div className="name">
              <h5 style={{ fontWeight: 700 }}>{profile?.user_profile?.name}</h5>
            </div>
          </div>
          <div className="text_area">
            <TextArea
              className="noBorderOnFocus border-0"
              rows={5}
              placeholder="What do you want to talk about?"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div className="imagessection">
              <Upload
                listType="picture-card"
                fileList={videoFiles}
                onChange={handleVideoChange}
                beforeUpload={() => false}
                accept="video/*"
                multiple={false}
              >
                {videoFiles.length < 1 && uploadButton}
              </Upload>
              <Upload
                listType="picture-card"
                fileList={thumbnailFile ? [thumbnailFile] : []}
                onChange={handleThumbnailChange}
                beforeUpload={() => false}
                accept="image/*"
                multiple={false}
              >
                {!thumbnailFile && uploadThumbnailButton}
              </Upload>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ShareReel;
