import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetStoryApi } from "../../apis/api";
import Layout from "../../Components/Layout/Layout";
import Posts from "../../Components/Profile/Posts/Posts";
import { fetchfeed } from "../../redux/slices/feedSlice";
import "./Explore.scss";

const Explore = () => {
  const Token = localStorage.getItem("glamlinkToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
console.log("random hit")
    if (Token) {

      dispatch(fetchfeed(Token));

      // GetStoryApi(Token).catch((e) => console.log(e, "error"));
    } else {
      navigate("/login");
    }
  }, [dispatch, Token, navigate]);

  const dataFeed = useSelector((state) => state.feed.feed.data);
console.log(dataFeed,"dataFeed")
  const handleMenuClick = ({ key }) => {
    navigate(`/${key}`);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="sharepost">Create Post</Menu.Item>
      <Menu.Item key="sharereel">Create Reels</Menu.Item>
    </Menu>
  );

  return (
    <Layout type="self">
      <div className="explore">
        <div className="top_heading">
          <h2>Explore</h2>
          <Dropdown overlay={menu} trigger={["click"]} className="createPost">
            <button className="createPostBtn">
              <PlusOutlined /> Create New
            </button>
          </Dropdown>
        </div>
        <div className="Feed_section">
          <Posts data={dataFeed} passby="explore" />
        </div>
      </div>
    </Layout>
  );
};

export default Explore;
