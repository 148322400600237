import React, { useState } from "react";
import logoImg from "../../../assest/Logo.png";
import { Button, Form, Input, message } from "antd";
import "./ForgotPassword.scss";
import OTPInput from "react-otp-input";

import { ForgotPasswordApi, VerifyAPi, sendOtpAPi } from "../../../apis/api";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate =useNavigate()
  const roleid = localStorage.getItem("role_id");
  const headers = {
    role_id: roleid,
  };
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");

  const [step, setStep] = useState(1);

  const handleSendOtp = async (values) => {
    const formData = {
      email: values?.email,
      type: "forgot_password",
    };

    sendOtpAPi(formData, headers)
      .then((res) => {
        message.success("OTP sent to your email!");
        setEmail(values.email);
        setStep(2);
      })
      .catch((e) => {
        console.log(e, "error");
      });

    // try {
    //   sendOtpAPi(formData,headers)
    //   message.success("OTP sent to your email!");
    //   setEmail(values.email);
    //   setStep(2);
    // } catch (error) {
    //   message.error("Failed to send OTP!");
    // }
  };

  const handleVerifyOtp = async () => {
    const formData = {
      email: email,
      otp: otp,
      type: "forgot_password",
    };

    VerifyAPi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message);
        // navigate("/login")
        setToken(res?.data?.data?.token);
        setStep(3);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePasswordChange = async (values) => {
    const formData = {
      email: email,
      password: values.password,
      confirm_password: values?.confirmPassword,
      token: token,
    };

    ForgotPasswordApi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message)
        navigate("/login")
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  return (
    <div className="ForgotPassword">
      <img src={logoImg} width={150} alt="logo" />
      <h3>Forgot Password!</h3>
      {step === 1 && (
        <div className="SendEmail">
          <div className="form">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={handleSendOtp}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  className="input_custom"
                  placeholder="example123@gmail.com"
                />
              </Form.Item>
              <Form.Item>
                <Button className="btn_theme" htmlType="submit" block>
                  Send OTP
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="VerifyOtp">
          <div className="form">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={handleVerifyOtp}
            >
              <Form.Item>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "100%",
                        height: "40px",
                        fontSize: "18px",
                        textAlign: "center",
                        color: "white",
                        background: "#25B8CB",
                        border: "none",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item>
                <Button className="btn_theme" htmlType="submit" block>
                  Verify OTP
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="forgot-password">
          <div className="form">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={handlePasswordChange}
            >
              <Form.Item
                label="Create Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                ]}
              >
                <Input.Password className="inputs" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password className="inputs" />
              </Form.Item>
              <Form.Item>
                <Button className="btn_theme" htmlType="submit" block>
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
